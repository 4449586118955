@font-face {
    font-family: Jost;
    src: url(jost/Jost-SemiItalic.woff2) format('woff2'), url(jost/Jost-SemiItalic.woff) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-Semi.woff2) format('woff2'), url(jost/Jost-Semi.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-Book.woff2) format('woff2'), url(jost/Jost-Book.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-LightItalic.woff2) format('woff2'), url(jost/Jost-LightItalic.woff) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-MediumItalic.woff2) format('woff2'), url(jost/Jost-MediumItalic.woff) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-BoldItalic.woff2) format('woff2'), url(jost/Jost-BoldItalic.woff) format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-Bold.woff2) format('woff2'), url(jost/Jost-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-BookItalic.woff2) format('woff2'), url(jost/Jost-BookItalic.woff) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-Light.woff2) format('woff2'), url(jost/Jost-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: Jost;
    src: url(jost/Jost-Medium.woff2) format('woff2'), url(jost/Jost-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-Medium.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-Light.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-BoldItalic.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-Italic.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-Bold.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-Regular.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-SemiBold.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-SemiBoldItalic.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-MediumItalic.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk/HKGrotesk-LightItalic.woff2') format('woff2'),
        url('hkgrotesk/HKGrotesk-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap 
}
@font-face {
    font-family: 'Museo Slab';
    src: url('museo/Museo300-Regular-webfont.woff2') format('woff2'),
         url('museo/Museo300-Regular-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'Museo Slab';
    src: url('museo/Museo500-Regular-webfont.woff2') format('woff2'),
         url('museo/Museo500-Regular-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap 
}
@font-face {
    font-family: 'Museo Slab';
    src: url('museo/Museo700-Regular-webfont.woff2') format('woff2'),
         url('museo/Museo700-Regular-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap 
}
@font-face {
  font-family: 'Jam';
  src:  url('jam/Jam.eot?j7o824');
  src:  url('jam/Jam.eot?j7o824#iefix') format('embedded-opentype'),
    url('jam/Jam.ttf?j7o824') format('truetype'),
    url('jam/Jam.woff?j7o824') format('woff'),
    url('jam/Jam.svg?j7o824#Jam') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap 
}
// @font-face { 
//   font-family: 'IcoFont';
//   src:  url('iconfont/icofont.eot');
//   src:  url('iconfont/icofont.eot') format('embedded-opentype'),
//     url('iconfont/icofont.ttf') format('truetype'),
//     url('iconfont/icofont.woff') format('woff'),
//     url('iconfont/icofont.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap 
// }
@font-face {
    font-family: 'Snowlake';
    src: url('snowlake/Snowlake.eot');
    src: url('snowlake/Snowlake.eot?#iefix') format('embedded-opentype'),
         url('snowlake/Snowlake.woff') format('woff'),
         url('snowlake/Snowlake.ttf') format('truetype'),
         url('snowlake/Snowlake.svg#Snowlake') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap 
}
@font-face {
  font-family: 'custom';
  src:  url('custom/custom.eot?gfza0');
  src:  url('custom/custom.eot?gfza0#iefix') format('embedded-opentype'),
    url('custom/custom.ttf?gfza0') format('truetype'),
    url('custom/custom.woff?gfza0') format('woff'),
    url('custom/custom.svg?gfza0#custom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap 
}
@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-Black.eot');
  src: url('Nunito/Nunito-Black.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-Black.woff2') format('woff2'),
      url('Nunito/Nunito-Black.woff') format('woff'),
      url('Nunito/Nunito-Black.ttf') format('truetype'),
      url('Nunito/Nunito-Black.svg#Nunito-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-BlackItalic.eot');
  src: url('Nunito/Nunito-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-BlackItalic.woff2') format('woff2'),
      url('Nunito/Nunito-BlackItalic.woff') format('woff'),
      url('Nunito/Nunito-BlackItalic.ttf') format('truetype'),
      url('Nunito/Nunito-BlackItalic.svg#Nunito-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-BoldItalic.eot');
  src: url('Nunito/Nunito-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-BoldItalic.woff2') format('woff2'),
      url('Nunito/Nunito-BoldItalic.woff') format('woff'),
      url('Nunito/Nunito-BoldItalic.ttf') format('truetype'),
      url('Nunito/Nunito-BoldItalic.svg#Nunito-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-Bold.eot');
  src: url('Nunito/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-Bold.woff2') format('woff2'),
      url('Nunito/Nunito-Bold.woff') format('woff'),
      url('Nunito/Nunito-Bold.ttf') format('truetype'),
      url('Nunito/Nunito-Bold.svg#Nunito-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-ExtraBold.eot');
  src: url('Nunito/Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-ExtraBold.woff2') format('woff2'),
      url('Nunito/Nunito-ExtraBold.woff') format('woff'),
      url('Nunito/Nunito-ExtraBold.ttf') format('truetype'),
      url('Nunito/Nunito-ExtraBold.svg#Nunito-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-ExtraBoldItalic.eot');
  src: url('Nunito/Nunito-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-ExtraBoldItalic.woff2') format('woff2'),
      url('Nunito/Nunito-ExtraBoldItalic.woff') format('woff'),
      url('Nunito/Nunito-ExtraBoldItalic.ttf') format('truetype'),
      url('Nunito/Nunito-ExtraBoldItalic.svg#Nunito-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-ExtraLight.eot');
  src: url('Nunito/Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-ExtraLight.woff2') format('woff2'),
      url('Nunito/Nunito-ExtraLight.woff') format('woff'),
      url('Nunito/Nunito-ExtraLight.ttf') format('truetype'),
      url('Nunito/Nunito-ExtraLight.svg#Nunito-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-ExtraLightItalic.eot');
  src: url('Nunito/Nunito-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-ExtraLightItalic.woff2') format('woff2'),
      url('Nunito/Nunito-ExtraLightItalic.woff') format('woff'),
      url('Nunito/Nunito-ExtraLightItalic.ttf') format('truetype'),
      url('Nunito/Nunito-ExtraLightItalic.svg#Nunito-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-Light.eot');
  src: url('Nunito/Nunito-Light.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-Light.woff2') format('woff2'),
      url('Nunito/Nunito-Light.woff') format('woff'),
      url('Nunito/Nunito-Light.ttf') format('truetype'),
      url('Nunito/Nunito-Light.svg#Nunito-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-LightItalic.eot');
  src: url('Nunito/Nunito-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-LightItalic.woff2') format('woff2'),
      url('Nunito/Nunito-LightItalic.woff') format('woff'),
      url('Nunito/Nunito-LightItalic.ttf') format('truetype'),
      url('Nunito/Nunito-LightItalic.svg#Nunito-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-Italic.eot');
  src: url('Nunito/Nunito-Italic.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-Italic.woff2') format('woff2'),
      url('Nunito/Nunito-Italic.woff') format('woff'),
      url('Nunito/Nunito-Italic.ttf') format('truetype'),
      url('Nunito/Nunito-Italic.svg#Nunito-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-SemiBold.eot');
  src: url('Nunito/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-SemiBold.woff2') format('woff2'),
      url('Nunito/Nunito-SemiBold.woff') format('woff'),
      url('Nunito/Nunito-SemiBold.ttf') format('truetype'),
      url('Nunito/Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-Regular.eot');
  src: url('Nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-Regular.woff2') format('woff2'),
      url('Nunito/Nunito-Regular.woff') format('woff'),
      url('Nunito/Nunito-Regular.ttf') format('truetype'),
      url('Nunito/Nunito-Regular.svg#Nunito-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito/Nunito-SemiBoldItalic.eot');
  src: url('Nunito/Nunito-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('Nunito/Nunito-SemiBoldItalic.woff2') format('woff2'),
      url('Nunito/Nunito-SemiBoldItalic.woff') format('woff'),
      url('Nunito/Nunito-SemiBoldItalic.ttf') format('truetype'),
      url('Nunito/Nunito-SemiBoldItalic.svg#Nunito-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}



[class^="c-"], [class*=" c-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Custom' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="jam-"], [class*=" jam-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Jam' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icofont-"], [class*=" icofont-"] {
  font-family: 'IcoFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-blob"], [class*=" icon-blob"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Snowlake' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-shape:before {
  content: "\0041";
}
.c-arrow-expand:before {
  content: "\e903";
}
.c-flower:before {
  content: "\e904";
}
.c-arrow-left:before {
  content: "\e900";
}
.c-arrow-right:before {
  content: "\e901";
}
.c-close:before {
  content: "\e902";
}
.jam-500px:before {
  content: "\e900";
}
.jam-activity:before {
  content: "\e901";
}
.jam-airbnb:before {
  content: "\e902";
}
.jam-alarm-clock:before {
  content: "\e904";
}
.jam-alarm-clock-f:before {
  content: "\e903";
}
.jam-alert:before {
  content: "\e906";
}
.jam-alert-f:before {
  content: "\e905";
}
.jam-alien:before {
  content: "\e908";
}
.jam-alien-f:before {
  content: "\e907";
}
.jam-align-center:before {
  content: "\e90a";
}
.jam-align-justify:before {
  content: "\e909";
}
.jam-align-left:before {
  content: "\e90b";
}
.jam-align-right:before {
  content: "\e90c";
}
.jam-amazon:before {
  content: "\e90d";
}
.jam-anchor:before {
  content: "\e90e";
}
.jam-android:before {
  content: "\e911";
}
.jam-android-circle:before {
  content: "\e90f";
}
.jam-android-square:before {
  content: "\e910";
}
.jam-aperture:before {
  content: "\e912";
}
.jam-apple:before {
  content: "\e915";
}
.jam-apple-circle:before {
  content: "\e913";
}
.jam-apple-square:before {
  content: "\e914";
}
.jam-archive:before {
  content: "\e917";
}
.jam-archive-f:before {
  content: "\e916";
}
.jam-arrow-circle-down:before {
  content: "\e91d";
}
.jam-arrow-circle-down-f:before {
  content: "\e918";
}
.jam-arrow-circle-down-left:before {
  content: "\e91a";
}
.jam-arrow-circle-down-left-f:before {
  content: "\e919";
}
.jam-arrow-circle-down-right:before {
  content: "\e91c";
}
.jam-arrow-circle-down-right-f:before {
  content: "\e91b";
}
.jam-arrow-circle-left:before {
  content: "\e91f";
}
.jam-arrow-circle-left-f:before {
  content: "\e91e";
}
.jam-arrow-circle-right:before {
  content: "\e921";
}
.jam-arrow-circle-right-f:before {
  content: "\e920";
}
.jam-arrow-circle-up:before {
  content: "\e927";
}
.jam-arrow-circle-up-f:before {
  content: "\e922";
}
.jam-arrow-circle-up-left:before {
  content: "\e924";
}
.jam-arrow-circle-up-left-f:before {
  content: "\e923";
}
.jam-arrow-circle-up-right:before {
  content: "\e926";
}
.jam-arrow-circle-up-right-f:before {
  content: "\e925";
}
.jam-arrow-down:before {
  content: "\e92a";
}
.jam-arrow-down-left:before {
  content: "\e928";
}
.jam-arrow-down-right:before {
  content: "\e929";
}
.jam-arrow-left:before {
  content: "\e92b";
}
.jam-arrow-right:before {
  content: "\e92c";
}
.jam-arrow-square-down:before {
  content: "\e932";
}
.jam-arrow-square-down-f:before {
  content: "\e92d";
}
.jam-arrow-square-down-left:before {
  content: "\e92f";
}
.jam-arrow-square-down-left-f:before {
  content: "\e92e";
}
.jam-arrow-square-down-right:before {
  content: "\e931";
}
.jam-arrow-square-down-right-f:before {
  content: "\e930";
}
.jam-arrow-square-left:before {
  content: "\e934";
}
.jam-arrow-square-left-f:before {
  content: "\e933";
}
.jam-arrow-square-right:before {
  content: "\e936";
}
.jam-arrow-square-right-f:before {
  content: "\e935";
}
.jam-arrow-square-up:before {
  content: "\e93c";
}
.jam-arrow-square-up-f:before {
  content: "\e937";
}
.jam-arrow-square-up-left:before {
  content: "\e939";
}
.jam-arrow-square-up-left-f:before {
  content: "\e938";
}
.jam-arrow-square-up-right:before {
  content: "\e93b";
}
.jam-arrow-square-up-right-f:before {
  content: "\e93a";
}
.jam-arrow-up:before {
  content: "\e93f";
}
.jam-arrow-up-left:before {
  content: "\e93d";
}
.jam-arrow-up-right:before {
  content: "\e93e";
}
.jam-arrows-corners:before {
  content: "\e940";
}
.jam-arrows-fullscreen:before {
  content: "\e941";
}
.jam-arrows-h:before {
  content: "\e942";
}
.jam-arrows-v:before {
  content: "\e943";
}
.jam-attachment:before {
  content: "\e944";
}
.jam-background-color:before {
  content: "\e945";
}
.jam-backpack:before {
  content: "\e947";
}
.jam-backpack-f:before {
  content: "\e946";
}
.jam-backward:before {
  content: "\e94c";
}
.jam-backward-circle:before {
  content: "\e949";
}
.jam-backward-circle-f:before {
  content: "\e948";
}
.jam-backward-square:before {
  content: "\e94b";
}
.jam-backward-square-f:before {
  content: "\e94a";
}
.jam-baidu:before {
  content: "\e94f";
}
.jam-baidu-circle:before {
  content: "\e94d";
}
.jam-baidu-square:before {
  content: "\e94e";
}
.jam-bandage:before {
  content: "\e951";
}
.jam-bandage-f:before {
  content: "\e950";
}
.jam-bar-chart:before {
  content: "\e952";
}
.jam-baseball:before {
  content: "\e953";
}
.jam-basketball:before {
  content: "\e954";
}
.jam-bathtub:before {
  content: "\e956";
}
.jam-bathtub-f:before {
  content: "\e955";
}
.jam-battery:before {
  content: "\e960";
}
.jam-battery-charging:before {
  content: "\e958";
}
.jam-battery-charging-f:before {
  content: "\e957";
}
.jam-battery-f:before {
  content: "\e959";
}
.jam-battery-half:before {
  content: "\e95b";
}
.jam-battery-half-f:before {
  content: "\e95a";
}
.jam-battery-one-quarter:before {
  content: "\e95d";
}
.jam-battery-one-quarter-f:before {
  content: "\e95c";
}
.jam-battery-three-quarters:before {
  content: "\e95f";
}
.jam-battery-three-quarters-f:before {
  content: "\e95e";
}
.jam-beatport:before {
  content: "\e963";
}
.jam-beatport-circle:before {
  content: "\e961";
}
.jam-beatport-square:before {
  content: "\e962";
}
.jam-beer:before {
  content: "\e965";
}
.jam-beer-f:before {
  content: "\e964";
}
.jam-behance:before {
  content: "\e968";
}
.jam-behance-circle:before {
  content: "\e966";
}
.jam-behance-square:before {
  content: "\e967";
}
.jam-bell:before {
  content: "\e96c";
}
.jam-bell-f:before {
  content: "\e969";
}
.jam-bell-off:before {
  content: "\e96b";
}
.jam-bell-off-f:before {
  content: "\e96a";
}
.jam-bing:before {
  content: "\e96f";
}
.jam-bing-circle:before {
  content: "\e96d";
}
.jam-bing-square:before {
  content: "\e96e";
}
.jam-birthday-cake:before {
  content: "\e971";
}
.jam-birthday-cake-f:before {
  content: "\e970";
}
.jam-blogger:before {
  content: "\e974";
}
.jam-blogger-circle:before {
  content: "\e972";
}
.jam-blogger-square:before {
  content: "\e973";
}
.jam-bluetooth:before {
  content: "\e975";
}
.jam-bold:before {
  content: "\e976";
}
.jam-book:before {
  content: "\e978";
}
.jam-book-f:before {
  content: "\e977";
}
.jam-bookmark:before {
  content: "\e980";
}
.jam-bookmark-f:before {
  content: "\e979";
}
.jam-bookmark-minus:before {
  content: "\e97b";
}
.jam-bookmark-minus-f:before {
  content: "\e97a";
}
.jam-bookmark-plus:before {
  content: "\e97d";
}
.jam-bookmark-plus-f:before {
  content: "\e97c";
}
.jam-bookmark-remove:before {
  content: "\e97f";
}
.jam-bookmark-remove-f:before {
  content: "\e97e";
}
.jam-bottle:before {
  content: "\e982";
}
.jam-bottle-f:before {
  content: "\e981";
}
.jam-box:before {
  content: "\e984";
}
.jam-box-f:before {
  content: "\e983";
}
.jam-branch:before {
  content: "\e986";
}
.jam-branch-f:before {
  content: "\e985";
}
.jam-brightness:before {
  content: "\e98b";
}
.jam-brightness-down:before {
  content: "\e988";
}
.jam-brightness-down-f:before {
  content: "\e987";
}
.jam-brightness-up:before {
  content: "\e98a";
}
.jam-brightness-up-f:before {
  content: "\e989";
}
.jam-brush:before {
  content: "\e98d";
}
.jam-brush-f:before {
  content: "\e98c";
}
.jam-bug:before {
  content: "\e98f";
}
.jam-bug-f:before {
  content: "\e98e";
}
.jam-building:before {
  content: "\e991";
}
.jam-building-f:before {
  content: "\e990";
}
.jam-bus:before {
  content: "\e993";
}
.jam-bus-f:before {
  content: "\e992";
}
.jam-cactus:before {
  content: "\e995";
}
.jam-cactus-f:before {
  content: "\e994";
}
.jam-calculator:before {
  content: "\e996";
}
.jam-calendar:before {
  content: "\e99a";
}
.jam-calendar-alt:before {
  content: "\e998";
}
.jam-calendar-alt-f:before {
  content: "\e997";
}
.jam-calendar-f:before {
  content: "\e999";
}
.jam-camera:before {
  content: "\e99e";
}
.jam-camera-alt:before {
  content: "\e99c";
}
.jam-camera-alt-f:before {
  content: "\e99b";
}
.jam-camera-f:before {
  content: "\e99d";
}
.jam-candle:before {
  content: "\e9a0";
}
.jam-candle-f:before {
  content: "\e99f";
}
.jam-capsule:before {
  content: "\e9a2";
}
.jam-capsule-f:before {
  content: "\e9a1";
}
.jam-car:before {
  content: "\e9a4";
}
.jam-car-f:before {
  content: "\e9a3";
}
.jam-castle:before {
  content: "\e9a6";
}
.jam-castle-f:before {
  content: "\e9a5";
}
.jam-check:before {
  content: "\e9a7";
}
.jam-chevron-circle-down:before {
  content: "\e9ad";
}
.jam-chevron-circle-down-f:before {
  content: "\e9a8";
}
.jam-chevron-circle-down-left:before {
  content: "\e9aa";
}
.jam-chevron-circle-down-left-f:before {
  content: "\e9a9";
}
.jam-chevron-circle-down-right:before {
  content: "\e9ac";
}
.jam-chevron-circle-down-right-f:before {
  content: "\e9ab";
}
.jam-chevron-circle-left:before {
  content: "\e9af";
}
.jam-chevron-circle-left-f:before {
  content: "\e9ae";
}
.jam-chevron-circle-right:before {
  content: "\e9b1";
}
.jam-chevron-circle-right-f:before {
  content: "\e9b0";
}
.jam-chevron-circle-up:before {
  content: "\e9b7";
}
.jam-chevron-circle-up-f:before {
  content: "\e9b2";
}
.jam-chevron-circle-up-left:before {
  content: "\e9b4";
}
.jam-chevron-circle-up-left-f:before {
  content: "\e9b3";
}
.jam-chevron-circle-up-right:before {
  content: "\e9b6";
}
.jam-chevron-circle-up-right-f:before {
  content: "\e9b5";
}
.jam-chevron-down:before {
  content: "\e9ba";
}
.jam-chevron-down-left:before {
  content: "\e9b8";
}
.jam-chevron-down-right:before {
  content: "\e9b9";
}
.jam-chevron-left:before {
  content: "\e9bb";
}
.jam-chevron-right:before {
  content: "\e9bc";
}
.jam-chevron-square-down:before {
  content: "\e9c2";
}
.jam-chevron-square-down-f:before {
  content: "\e9bd";
}
.jam-chevron-square-down-left:before {
  content: "\e9bf";
}
.jam-chevron-square-down-left-f:before {
  content: "\e9be";
}
.jam-chevron-square-down-right:before {
  content: "\e9c1";
}
.jam-chevron-square-down-right-f:before {
  content: "\e9c0";
}
.jam-chevron-square-left:before {
  content: "\e9c4";
}
.jam-chevron-square-left-f:before {
  content: "\e9c3";
}
.jam-chevron-square-right:before {
  content: "\e9c6";
}
.jam-chevron-square-right-f:before {
  content: "\e9c5";
}
.jam-chevron-square-up:before {
  content: "\e9cc";
}
.jam-chevron-square-up-f:before {
  content: "\e9c7";
}
.jam-chevron-square-up-left:before {
  content: "\e9c9";
}
.jam-chevron-square-up-left-f:before {
  content: "\e9c8";
}
.jam-chevron-square-up-right:before {
  content: "\e9cb";
}
.jam-chevron-square-up-right-f:before {
  content: "\e9ca";
}
.jam-chevron-up:before {
  content: "\e9cf";
}
.jam-chevron-up-left:before {
  content: "\e9cd";
}
.jam-chevron-up-right:before {
  content: "\e9ce";
}
.jam-chevrons-circle-down:before {
  content: "\e9d5";
}
.jam-chevrons-circle-down-f:before {
  content: "\e9d0";
}
.jam-chevrons-circle-down-left:before {
  content: "\e9d2";
}
.jam-chevrons-circle-down-left-f:before {
  content: "\e9d1";
}
.jam-chevrons-circle-down-right:before {
  content: "\e9d4";
}
.jam-chevrons-circle-down-right-f:before {
  content: "\e9d3";
}
.jam-chevrons-circle-left:before {
  content: "\e9d7";
}
.jam-chevrons-circle-left-f:before {
  content: "\e9d6";
}
.jam-chevrons-circle-right:before {
  content: "\e9d9";
}
.jam-chevrons-circle-right-f:before {
  content: "\e9d8";
}
.jam-chevrons-circle-up:before {
  content: "\e9df";
}
.jam-chevrons-circle-up-f:before {
  content: "\e9da";
}
.jam-chevrons-circle-up-left:before {
  content: "\e9dc";
}
.jam-chevrons-circle-up-left-f:before {
  content: "\e9db";
}
.jam-chevrons-circle-up-right:before {
  content: "\e9de";
}
.jam-chevrons-circle-up-right-f:before {
  content: "\e9dd";
}
.jam-chevrons-down:before {
  content: "\e9e2";
}
.jam-chevrons-down-left:before {
  content: "\e9e0";
}
.jam-chevrons-down-right:before {
  content: "\e9e1";
}
.jam-chevrons-left:before {
  content: "\e9e3";
}
.jam-chevrons-right:before {
  content: "\e9e4";
}
.jam-chevrons-square-down:before {
  content: "\e9ea";
}
.jam-chevrons-square-down-f:before {
  content: "\e9e5";
}
.jam-chevrons-square-down-left:before {
  content: "\e9e7";
}
.jam-chevrons-square-down-left-f:before {
  content: "\e9e6";
}
.jam-chevrons-square-down-right:before {
  content: "\e9e9";
}
.jam-chevrons-square-down-right-f:before {
  content: "\e9e8";
}
.jam-chevrons-square-left:before {
  content: "\e9ec";
}
.jam-chevrons-square-left-f:before {
  content: "\e9eb";
}
.jam-chevrons-square-right:before {
  content: "\e9ee";
}
.jam-chevrons-square-right-f:before {
  content: "\e9ed";
}
.jam-chevrons-square-up:before {
  content: "\e9f4";
}
.jam-chevrons-square-up-f:before {
  content: "\e9ef";
}
.jam-chevrons-square-up-left:before {
  content: "\e9f1";
}
.jam-chevrons-square-up-left-f:before {
  content: "\e9f0";
}
.jam-chevrons-square-up-right:before {
  content: "\e9f3";
}
.jam-chevrons-square-up-right-f:before {
  content: "\e9f2";
}
.jam-chevrons-up:before {
  content: "\e9f7";
}
.jam-chevrons-up-left:before {
  content: "\e9f5";
}
.jam-chevrons-up-right:before {
  content: "\e9f6";
}
.jam-chronometer:before {
  content: "\e9f9";
}
.jam-chronometer-f:before {
  content: "\e9f8";
}
.jam-circle:before {
  content: "\e9fb";
}
.jam-circle-f:before {
  content: "\e9fa";
}
.jam-clear-format:before {
  content: "\e9fc";
}
.jam-clipboard:before {
  content: "\e9fe";
}
.jam-clipboard-f:before {
  content: "\e9fd";
}
.jam-clock:before {
  content: "\ea00";
}
.jam-clock-f:before {
  content: "\e9ff";
}
.jam-close:before {
  content: "\ea05";
}
.jam-close-circle:before {
  content: "\ea02";
}
.jam-close-circle-f:before {
  content: "\ea01";
}
.jam-close-rectangle:before {
  content: "\ea04";
}
.jam-close-rectangle-f:before {
  content: "\ea03";
}
.jam-cloud:before {
  content: "\ea0d";
}
.jam-cloud-f:before {
  content: "\ea06";
}
.jam-cloud-rain:before {
  content: "\ea08";
}
.jam-cloud-rain-f:before {
  content: "\ea07";
}
.jam-cloud-snow:before {
  content: "\ea0a";
}
.jam-cloud-snow-f:before {
  content: "\ea09";
}
.jam-cloud-thunder:before {
  content: "\ea0c";
}
.jam-cloud-thunder-f:before {
  content: "\ea0b";
}
.jam-codepen:before {
  content: "\ea10";
}
.jam-codepen-circle:before {
  content: "\ea0e";
}
.jam-codepen-square:before {
  content: "\ea0f";
}
.jam-coffee:before {
  content: "\ea13";
}
.jam-coffee-cup:before {
  content: "\ea12";
}
.jam-coffee-cup-f:before {
  content: "\ea11";
}
.jam-cog:before {
  content: "\ea15";
}
.jam-cog-f:before {
  content: "\ea14";
}
.jam-cogs:before {
  content: "\ea17";
}
.jam-cogs-f:before {
  content: "\ea16";
}
.jam-coin:before {
  content: "\ea19";
}
.jam-coin-f:before {
  content: "\ea18";
}
.jam-color:before {
  content: "\ea1a";
}
.jam-compass:before {
  content: "\ea1c";
}
.jam-compass-f:before {
  content: "\ea1b";
}
.jam-computer:before {
  content: "\ea20";
}
.jam-computer-alt:before {
  content: "\ea1e";
}
.jam-computer-alt-f:before {
  content: "\ea1d";
}
.jam-computer-f:before {
  content: "\ea1f";
}
.jam-credit-card:before {
  content: "\ea22";
}
.jam-credit-card-f:before {
  content: "\ea21";
}
.jam-crop:before {
  content: "\ea23";
}
.jam-crown:before {
  content: "\ea25";
}
.jam-crown-f:before {
  content: "\ea24";
}
.jam-cutlery:before {
  content: "\ea27";
}
.jam-cutlery-f:before {
  content: "\ea26";
}
.jam-cutter:before {
  content: "\ea29";
}
.jam-cutter-f:before {
  content: "\ea28";
}
.jam-dashboard:before {
  content: "\ea2b";
}
.jam-dashboard-f:before {
  content: "\ea2a";
}
.jam-database:before {
  content: "\ea2d";
}
.jam-database-f:before {
  content: "\ea2c";
}
.jam-deezer:before {
  content: "\ea30";
}
.jam-deezer-circle:before {
  content: "\ea2e";
}
.jam-deezer-square:before {
  content: "\ea2f";
}
.jam-delete:before {
  content: "\ea32";
}
.jam-delete-f:before {
  content: "\ea31";
}
.jam-deviantart:before {
  content: "\ea35";
}
.jam-deviantart-circle:before {
  content: "\ea33";
}
.jam-deviantart-square:before {
  content: "\ea34";
}
.jam-digg:before {
  content: "\ea36";
}
.jam-direction:before {
  content: "\ea38";
}
.jam-direction-f:before {
  content: "\ea37";
}
.jam-directions:before {
  content: "\ea3a";
}
.jam-directions-f:before {
  content: "\ea39";
}
.jam-disc:before {
  content: "\ea3c";
}
.jam-disc-f:before {
  content: "\ea3b";
}
.jam-disqus:before {
  content: "\ea3f";
}
.jam-disqus-circle:before {
  content: "\ea3d";
}
.jam-disqus-square:before {
  content: "\ea3e";
}
.jam-dj:before {
  content: "\ea41";
}
.jam-dj-f:before {
  content: "\ea40";
}
.jam-dna:before {
  content: "\ea42";
}
.jam-document:before {
  content: "\ea44";
}
.jam-document-f:before {
  content: "\ea43";
}
.jam-door:before {
  content: "\ea45";
}
.jam-download:before {
  content: "\ea46";
}
.jam-drupal:before {
  content: "\ea49";
}
.jam-drupal-circle:before {
  content: "\ea47";
}
.jam-drupal-square:before {
  content: "\ea48";
}
.jam-ebay:before {
  content: "\ea4a";
}
.jam-egg:before {
  content: "\ea4c";
}
.jam-egg-f:before {
  content: "\ea4b";
}
.jam-eggs:before {
  content: "\ea4e";
}
.jam-eggs-f:before {
  content: "\ea4d";
}
.jam-eject:before {
  content: "\ea53";
}
.jam-eject-circle:before {
  content: "\ea50";
}
.jam-eject-circle-f:before {
  content: "\ea4f";
}
.jam-eject-square:before {
  content: "\ea52";
}
.jam-eject-square-f:before {
  content: "\ea51";
}
.jam-envelope:before {
  content: "\ea57";
}
.jam-envelope-f:before {
  content: "\ea54";
}
.jam-envelope-open:before {
  content: "\ea56";
}
.jam-envelope-open-f:before {
  content: "\ea55";
}
.jam-extinguisher:before {
  content: "\ea59";
}
.jam-extinguisher-f:before {
  content: "\ea58";
}
.jam-eye:before {
  content: "\ea5d";
}
.jam-eye-close:before {
  content: "\ea5b";
}
.jam-eye-close-f:before {
  content: "\ea5a";
}
.jam-eye-f:before {
  content: "\ea5c";
}
.jam-eyedropper:before {
  content: "\ea5f";
}
.jam-eyedropper-f:before {
  content: "\ea5e";
}
.jam-facebook:before {
  content: "\ea62";
}
.jam-facebook-circle:before {
  content: "\ea60";
}
.jam-facebook-square:before {
  content: "\ea61";
}
.jam-fast-backward:before {
  content: "\ea67";
}
.jam-fast-backward-circle:before {
  content: "\ea64";
}
.jam-fast-backward-circle-f:before {
  content: "\ea63";
}
.jam-fast-backward-square:before {
  content: "\ea66";
}
.jam-fast-backward-square-f:before {
  content: "\ea65";
}
.jam-fast-forward:before {
  content: "\ea6c";
}
.jam-fast-forward-circle:before {
  content: "\ea69";
}
.jam-fast-forward-circle-f:before {
  content: "\ea68";
}
.jam-fast-forward-square:before {
  content: "\ea6b";
}
.jam-fast-forward-square-f:before {
  content: "\ea6a";
}
.jam-feather:before {
  content: "\ea6e";
}
.jam-feather-f:before {
  content: "\ea6d";
}
.jam-female:before {
  content: "\ea6f";
}
.jam-file:before {
  content: "\ea71";
}
.jam-file-f:before {
  content: "\ea70";
}
.jam-files:before {
  content: "\ea73";
}
.jam-files-f:before {
  content: "\ea72";
}
.jam-filter:before {
  content: "\ea75";
}
.jam-filter-f:before {
  content: "\ea74";
}
.jam-fingerprint:before {
  content: "\ea76";
}
.jam-first-aid:before {
  content: "\ea78";
}
.jam-first-aid-f:before {
  content: "\ea77";
}
.jam-fish:before {
  content: "\ea79";
}
.jam-fiverr:before {
  content: "\ea7c";
}
.jam-fiverr-circle:before {
  content: "\ea7a";
}
.jam-fiverr-square:before {
  content: "\ea7b";
}
.jam-flag:before {
  content: "\ea7e";
}
.jam-flag-f:before {
  content: "\ea7d";
}
.jam-flame:before {
  content: "\ea80";
}
.jam-flame-f:before {
  content: "\ea7f";
}
.jam-flashlight-off:before {
  content: "\ea82";
}
.jam-flashlight-off-f:before {
  content: "\ea81";
}
.jam-flashlight-on:before {
  content: "\ea84";
}
.jam-flashlight-on-f:before {
  content: "\ea83";
}
.jam-flask:before {
  content: "\ea85";
}
.jam-flickr:before {
  content: "\ea88";
}
.jam-flickr-circle:before {
  content: "\ea86";
}
.jam-flickr-square:before {
  content: "\ea87";
}
.jam-flower:before {
  content: "\ea89";
}
.jam-folder:before {
  content: "\ea8f";
}
.jam-folder-f:before {
  content: "\ea8a";
}
.jam-folder-open:before {
  content: "\ea8c";
}
.jam-folder-open-f:before {
  content: "\ea8b";
}
.jam-folder-zip:before {
  content: "\ea8e";
}
.jam-folder-zip-f:before {
  content: "\ea8d";
}
.jam-football:before {
  content: "\ea90";
}
.jam-fork:before {
  content: "\ea92";
}
.jam-fork-f:before {
  content: "\ea91";
}
.jam-forward:before {
  content: "\ea97";
}
.jam-forward-circle:before {
  content: "\ea94";
}
.jam-forward-circle-f:before {
  content: "\ea93";
}
.jam-forward-square:before {
  content: "\ea96";
}
.jam-forward-square-f:before {
  content: "\ea95";
}
.jam-foursquare:before {
  content: "\ea98";
}
.jam-gamepad:before {
  content: "\ea9c";
}
.jam-gamepad-f:before {
  content: "\ea99";
}
.jam-gamepad-retro:before {
  content: "\ea9b";
}
.jam-gamepad-retro-f:before {
  content: "\ea9a";
}
.jam-ghost:before {
  content: "\eaa1";
}
.jam-ghost-f:before {
  content: "\ea9d";
}
.jam-ghost-org:before {
  content: "\eaa0";
}
.jam-ghost-org-circle:before {
  content: "\ea9e";
}
.jam-ghost-org-square:before {
  content: "\ea9f";
}
.jam-gift:before {
  content: "\eaa3";
}
.jam-gift-f:before {
  content: "\eaa2";
}
.jam-github:before {
  content: "\eaa6";
}
.jam-github-circle:before {
  content: "\eaa4";
}
.jam-github-square:before {
  content: "\eaa5";
}
.jam-gitlab:before {
  content: "\eaa9";
}
.jam-gitlab-circle:before {
  content: "\eaa7";
}
.jam-gitlab-square:before {
  content: "\eaa8";
}
.jam-glass-empty:before {
  content: "\eaaa";
}
.jam-glass-filled:before {
  content: "\eaac";
}
.jam-glass-filled-f:before {
  content: "\eaab";
}
.jam-glue:before {
  content: "\eaae";
}
.jam-glue-f:before {
  content: "\eaad";
}
.jam-google:before {
  content: "\eab7";
}
.jam-google-circle:before {
  content: "\eaaf";
}
.jam-google-play:before {
  content: "\eab2";
}
.jam-google-play-circle:before {
  content: "\eab0";
}
.jam-google-play-square:before {
  content: "\eab1";
}
.jam-google-plus:before {
  content: "\eab5";
}
.jam-google-plus-circle:before {
  content: "\eab3";
}
.jam-google-plus-square:before {
  content: "\eab4";
}
.jam-google-square:before {
  content: "\eab6";
}
.jam-gps:before {
  content: "\eab9";
}
.jam-gps-f:before {
  content: "\eab8";
}
.jam-grid:before {
  content: "\eabb";
}
.jam-grid-f:before {
  content: "\eaba";
}
.jam-hairdryer:before {
  content: "\eabd";
}
.jam-hairdryer-f:before {
  content: "\eabc";
}
.jam-hammer:before {
  content: "\eabf";
}
.jam-hammer-f:before {
  content: "\eabe";
}
.jam-hashtag:before {
  content: "\eac0";
}
.jam-header:before {
  content: "\eac7";
}
.jam-header-1:before {
  content: "\eac1";
}
.jam-header-2:before {
  content: "\eac2";
}
.jam-header-3:before {
  content: "\eac3";
}
.jam-header-4:before {
  content: "\eac4";
}
.jam-header-5:before {
  content: "\eac5";
}
.jam-header-6:before {
  content: "\eac6";
}
.jam-headset:before {
  content: "\eac9";
}
.jam-headset-f:before {
  content: "\eac8";
}
.jam-heart:before {
  content: "\eacb";
}
.jam-heart-f:before {
  content: "\eaca";
}
.jam-helmet:before {
  content: "\eacd";
}
.jam-helmet-f:before {
  content: "\eacc";
}
.jam-help:before {
  content: "\eacf";
}
.jam-help-f:before {
  content: "\eace";
}
.jam-highlighter:before {
  content: "\ead1";
}
.jam-highlighter-f:before {
  content: "\ead0";
}
.jam-history:before {
  content: "\ead2";
}
.jam-home:before {
  content: "\ead4";
}
.jam-home-f:before {
  content: "\ead3";
}
.jam-hourglass:before {
  content: "\ead6";
}
.jam-hourglass-f:before {
  content: "\ead5";
}
.jam-ice-cream:before {
  content: "\ead8";
}
.jam-ice-cream-f:before {
  content: "\ead7";
}
.jam-id-card:before {
  content: "\eada";
}
.jam-id-card-f:before {
  content: "\ead9";
}
.jam-inbox:before {
  content: "\eadc";
}
.jam-inbox-f:before {
  content: "\eadb";
}
.jam-inboxes:before {
  content: "\eade";
}
.jam-inboxes-f:before {
  content: "\eadd";
}
.jam-indent:before {
  content: "\eadf";
}
.jam-infinite:before {
  content: "\eae0";
}
.jam-info:before {
  content: "\eae2";
}
.jam-info-f:before {
  content: "\eae1";
}
.jam-instagram:before {
  content: "\eae3";
}
.jam-instant-picture:before {
  content: "\eae5";
}
.jam-instant-picture-f:before {
  content: "\eae4";
}
.jam-italic:before {
  content: "\eae6";
}
.jam-joystick:before {
  content: "\eae8";
}
.jam-joystick-f:before {
  content: "\eae7";
}
.jam-key:before {
  content: "\eaea";
}
.jam-key-f:before {
  content: "\eae9";
}
.jam-keyboard:before {
  content: "\eaec";
}
.jam-keyboard-f:before {
  content: "\eaeb";
}
.jam-language:before {
  content: "\eaed";
}
.jam-layers:before {
  content: "\eaef";
}
.jam-layers-f:before {
  content: "\eaee";
}
.jam-layout:before {
  content: "\eaf1";
}
.jam-layout-f:before {
  content: "\eaf0";
}
.jam-leaf:before {
  content: "\eaf3";
}
.jam-leaf-f:before {
  content: "\eaf2";
}
.jam-lifebuoy:before {
  content: "\eaf5";
}
.jam-lifebuoy-f:before {
  content: "\eaf4";
}
.jam-lightbulb:before {
  content: "\eaf7";
}
.jam-lightbulb-f:before {
  content: "\eaf6";
}
.jam-line:before {
  content: "\eaf8";
}
.jam-link:before {
  content: "\eaf9";
}
.jam-linkedin:before {
  content: "\eafc";
}
.jam-linkedin-circle:before {
  content: "\eafa";
}
.jam-linkedin-square:before {
  content: "\eafb";
}
.jam-log-in:before {
  content: "\eafd";
}
.jam-log-out:before {
  content: "\eafe";
}
.jam-luggage:before {
  content: "\eb00";
}
.jam-luggage-f:before {
  content: "\eaff";
}
.jam-magic:before {
  content: "\eb02";
}
.jam-magic-f:before {
  content: "\eb01";
}
.jam-magnet:before {
  content: "\eb04";
}
.jam-magnet-f:before {
  content: "\eb03";
}
.jam-male:before {
  content: "\eb05";
}
.jam-map:before {
  content: "\eb09";
}
.jam-map-f:before {
  content: "\eb06";
}
.jam-map-marker:before {
  content: "\eb08";
}
.jam-map-marker-f:before {
  content: "\eb07";
}
.jam-mask:before {
  content: "\eb0b";
}
.jam-mask-f:before {
  content: "\eb0a";
}
.jam-medal:before {
  content: "\eb0d";
}
.jam-medal-f:before {
  content: "\eb0c";
}
.jam-medical:before {
  content: "\eb0e";
}
.jam-medium:before {
  content: "\eb11";
}
.jam-medium-circle:before {
  content: "\eb0f";
}
.jam-medium-square:before {
  content: "\eb10";
}
.jam-menu:before {
  content: "\eb12";
}
.jam-merge:before {
  content: "\eb14";
}
.jam-merge-f:before {
  content: "\eb13";
}
.jam-message:before {
  content: "\eb1c";
}
.jam-message-alt:before {
  content: "\eb18";
}
.jam-message-alt-f:before {
  content: "\eb15";
}
.jam-message-alt-writing:before {
  content: "\eb17";
}
.jam-message-alt-writing-f:before {
  content: "\eb16";
}
.jam-message-f:before {
  content: "\eb19";
}
.jam-message-writing:before {
  content: "\eb1b";
}
.jam-message-writing-f:before {
  content: "\eb1a";
}
.jam-messages:before {
  content: "\eb20";
}
.jam-messages-alt:before {
  content: "\eb1e";
}
.jam-messages-alt-f:before {
  content: "\eb1d";
}
.jam-messages-f:before {
  content: "\eb1f";
}
.jam-messenger:before {
  content: "\eb21";
}
.jam-mic:before {
  content: "\eb28";
}
.jam-mic-alt:before {
  content: "\eb22";
}
.jam-mic-circle:before {
  content: "\eb24";
}
.jam-mic-circle-f:before {
  content: "\eb23";
}
.jam-mic-f:before {
  content: "\eb25";
}
.jam-mic-square:before {
  content: "\eb27";
}
.jam-mic-square-f:before {
  content: "\eb26";
}
.jam-microchip:before {
  content: "\eb2a";
}
.jam-microchip-f:before {
  content: "\eb29";
}
.jam-minus:before {
  content: "\eb2f";
}
.jam-minus-circle:before {
  content: "\eb2c";
}
.jam-minus-circle-f:before {
  content: "\eb2b";
}
.jam-minus-rectangle:before {
  content: "\eb2e";
}
.jam-minus-rectangle-f:before {
  content: "\eb2d";
}
.jam-moon:before {
  content: "\eb31";
}
.jam-moon-f:before {
  content: "\eb30";
}
.jam-more-horizontal:before {
  content: "\eb33";
}
.jam-more-horizontal-f:before {
  content: "\eb32";
}
.jam-more-vertical:before {
  content: "\eb35";
}
.jam-more-vertical-f:before {
  content: "\eb34";
}
.jam-mountain:before {
  content: "\eb37";
}
.jam-mountain-f:before {
  content: "\eb36";
}
.jam-move:before {
  content: "\eb39";
}
.jam-move-alt:before {
  content: "\eb38";
}
.jam-movie:before {
  content: "\eb3a";
}
.jam-mug:before {
  content: "\eb3c";
}
.jam-mug-f:before {
  content: "\eb3b";
}
.jam-music:before {
  content: "\eb3e";
}
.jam-music-f:before {
  content: "\eb3d";
}
.jam-myspace:before {
  content: "\eb41";
}
.jam-myspace-circle:before {
  content: "\eb3f";
}
.jam-myspace-square:before {
  content: "\eb40";
}
.jam-napster:before {
  content: "\eb44";
}
.jam-napster-circle:before {
  content: "\eb42";
}
.jam-napster-square:before {
  content: "\eb43";
}
.jam-newsletter:before {
  content: "\eb46";
}
.jam-newsletter-f:before {
  content: "\eb45";
}
.jam-newspaper:before {
  content: "\eb48";
}
.jam-newspaper-f:before {
  content: "\eb47";
}
.jam-npm:before {
  content: "\eb49";
}
.jam-odnoklassniki:before {
  content: "\eb4c";
}
.jam-odnoklassniki-circle:before {
  content: "\eb4a";
}
.jam-odnoklassniki-square:before {
  content: "\eb4b";
}
.jam-opera:before {
  content: "\eb4f";
}
.jam-opera-circle:before {
  content: "\eb4d";
}
.jam-opera-square:before {
  content: "\eb4e";
}
.jam-ordered-list:before {
  content: "\eb50";
}
.jam-padlock:before {
  content: "\eb58";
}
.jam-padlock-alt:before {
  content: "\eb54";
}
.jam-padlock-alt-f:before {
  content: "\eb51";
}
.jam-padlock-alt-open:before {
  content: "\eb53";
}
.jam-padlock-alt-open-f:before {
  content: "\eb52";
}
.jam-padlock-f:before {
  content: "\eb55";
}
.jam-padlock-open:before {
  content: "\eb57";
}
.jam-padlock-open-f:before {
  content: "\eb56";
}
.jam-paper-plane:before {
  content: "\eb5a";
}
.jam-paper-plane-f:before {
  content: "\eb59";
}
.jam-patreon:before {
  content: "\eb5d";
}
.jam-patreon-circle:before {
  content: "\eb5b";
}
.jam-patreon-square:before {
  content: "\eb5c";
}
.jam-pause:before {
  content: "\eb5e";
}
.jam-paypal:before {
  content: "\eb61";
}
.jam-paypal-circle:before {
  content: "\eb5f";
}
.jam-paypal-square:before {
  content: "\eb60";
}
.jam-pen:before {
  content: "\eb63";
}
.jam-pen-f:before {
  content: "\eb62";
}
.jam-pencil:before {
  content: "\eb65";
}
.jam-pencil-f:before {
  content: "\eb64";
}
.jam-periscope:before {
  content: "\eb68";
}
.jam-periscope-circle:before {
  content: "\eb66";
}
.jam-periscope-square:before {
  content: "\eb67";
}
.jam-phone:before {
  content: "\eb6a";
}
.jam-phone-f:before {
  content: "\eb69";
}
.jam-picture:before {
  content: "\eb6c";
}
.jam-picture-f:before {
  content: "\eb6b";
}
.jam-pictures:before {
  content: "\eb6e";
}
.jam-pictures-f:before {
  content: "\eb6d";
}
.jam-pie-chart:before {
  content: "\eb71";
}
.jam-pie-chart-alt:before {
  content: "\eb6f";
}
.jam-pie-chart-f:before {
  content: "\eb70";
}
.jam-pin:before {
  content: "\eb75";
}
.jam-pin-alt:before {
  content: "\eb73";
}
.jam-pin-alt-f:before {
  content: "\eb72";
}
.jam-pin-f:before {
  content: "\eb74";
}
.jam-pinterest:before {
  content: "\eb78";
}
.jam-pinterest-circle:before {
  content: "\eb76";
}
.jam-pinterest-square:before {
  content: "\eb77";
}
.jam-pizza-slice:before {
  content: "\eb79";
}
.jam-plane:before {
  content: "\eb7b";
}
.jam-plane-f:before {
  content: "\eb7a";
}
.jam-play:before {
  content: "\eb80";
}
.jam-play-circle:before {
  content: "\eb7d";
}
.jam-play-circle-f:before {
  content: "\eb7c";
}
.jam-play-square:before {
  content: "\eb7f";
}
.jam-play-square-f:before {
  content: "\eb7e";
}
.jam-plug:before {
  content: "\eb82";
}
.jam-plug-f:before {
  content: "\eb81";
}
.jam-plus:before {
  content: "\eb87";
}
.jam-plus-circle:before {
  content: "\eb84";
}
.jam-plus-circle-f:before {
  content: "\eb83";
}
.jam-plus-rectangle:before {
  content: "\eb86";
}
.jam-plus-rectangle-f:before {
  content: "\eb85";
}
.jam-pocket-watch:before {
  content: "\eb89";
}
.jam-pocket-watch-f:before {
  content: "\eb88";
}
.jam-podcast:before {
  content: "\eb8a";
}
.jam-power:before {
  content: "\eb8b";
}
.jam-printer:before {
  content: "\eb8d";
}
.jam-printer-f:before {
  content: "\eb8c";
}
.jam-px:before {
  content: "\e900";
}
.jam-qr-code:before {
  content: "\eb8e";
}
.jam-quora:before {
  content: "\eb91";
}
.jam-quora-circle:before {
  content: "\eb8f";
}
.jam-quora-square:before {
  content: "\eb90";
}
.jam-rainbow:before {
  content: "\eb92";
}
.jam-rec:before {
  content: "\eb93";
}
.jam-rectangle:before {
  content: "\eb95";
}
.jam-rectangle-f:before {
  content: "\eb94";
}
.jam-reddit:before {
  content: "\eb96";
}
.jam-redo:before {
  content: "\eb97";
}
.jam-refresh:before {
  content: "\eb99";
}
.jam-refresh-reverse:before {
  content: "\eb98";
}
.jam-repeat:before {
  content: "\eb9a";
}
.jam-rocket:before {
  content: "\eb9c";
}
.jam-rocket-f:before {
  content: "\eb9b";
}
.jam-rss-feed:before {
  content: "\eb9d";
}
.jam-rubber:before {
  content: "\eb9e";
}
.jam-ruler:before {
  content: "\eba0";
}
.jam-ruler-f:before {
  content: "\eb9f";
}
.jam-save:before {
  content: "\eba2";
}
.jam-save-f:before {
  content: "\eba1";
}
.jam-scissors:before {
  content: "\eba3";
}
.jam-screen:before {
  content: "\eba5";
}
.jam-screen-f:before {
  content: "\eba4";
}
.jam-screwdriver:before {
  content: "\eba7";
}
.jam-screwdriver-f:before {
  content: "\eba6";
}
.jam-search:before {
  content: "\ebab";
}
.jam-search-folder:before {
  content: "\eba8";
}
.jam-search-minus:before {
  content: "\eba9";
}
.jam-search-plus:before {
  content: "\ebaa";
}
.jam-set-backward:before {
  content: "\ebb0";
}
.jam-set-backward-circle:before {
  content: "\ebad";
}
.jam-set-backward-circle-f:before {
  content: "\ebac";
}
.jam-set-backward-square:before {
  content: "\ebaf";
}
.jam-set-backward-square-f:before {
  content: "\ebae";
}
.jam-set-forward:before {
  content: "\ebb5";
}
.jam-set-forward-circle:before {
  content: "\ebb2";
}
.jam-set-forward-circle-f:before {
  content: "\ebb1";
}
.jam-set-forward-square:before {
  content: "\ebb4";
}
.jam-set-forward-square-f:before {
  content: "\ebb3";
}
.jam-set-square:before {
  content: "\ebb7";
}
.jam-set-square-f:before {
  content: "\ebb6";
}
.jam-settings-alt:before {
  content: "\ebb8";
}
.jam-share:before {
  content: "\ebbb";
}
.jam-share-alt:before {
  content: "\ebba";
}
.jam-share-alt-f:before {
  content: "\ebb9";
}
.jam-shield:before {
  content: "\ebc6";
}
.jam-shield-check:before {
  content: "\ebbd";
}
.jam-shield-check-f:before {
  content: "\ebbc";
}
.jam-shield-close:before {
  content: "\ebbf";
}
.jam-shield-close-f:before {
  content: "\ebbe";
}
.jam-shield-f:before {
  content: "\ebc0";
}
.jam-shield-half:before {
  content: "\ebc1";
}
.jam-shield-minus:before {
  content: "\ebc3";
}
.jam-shield-minus-f:before {
  content: "\ebc2";
}
.jam-shield-plus:before {
  content: "\ebc5";
}
.jam-shield-plus-f:before {
  content: "\ebc4";
}
.jam-shopify:before {
  content: "\ebc9";
}
.jam-shopify-circle:before {
  content: "\ebc7";
}
.jam-shopify-square:before {
  content: "\ebc8";
}
.jam-shopping-bag:before {
  content: "\ebcd";
}
.jam-shopping-bag-alt:before {
  content: "\ebcb";
}
.jam-shopping-bag-alt-f:before {
  content: "\ebca";
}
.jam-shopping-bag-f:before {
  content: "\ebcc";
}
.jam-shopping-cart:before {
  content: "\ebce";
}
.jam-shuffle:before {
  content: "\ebcf";
}
.jam-signal:before {
  content: "\ebd0";
}
.jam-sitemap:before {
  content: "\ebd2";
}
.jam-sitemap-f:before {
  content: "\ebd1";
}
.jam-skull:before {
  content: "\ebd4";
}
.jam-skull-f:before {
  content: "\ebd3";
}
.jam-skype:before {
  content: "\ebd7";
}
.jam-skype-circle:before {
  content: "\ebd5";
}
.jam-skype-square:before {
  content: "\ebd6";
}
.jam-smiley:before {
  content: "\ebdc";
}
.jam-smiley-f:before {
  content: "\ebdb";
}
.jam-snapchat:before {
  content: "\ebdf";
}
.jam-snapchat-circle:before {
  content: "\ebdd";
}
.jam-snapchat-square:before {
  content: "\ebde";
}
.jam-snowboard:before {
  content: "\ebe1";
}
.jam-snowboard-f:before {
  content: "\ebe0";
}
.jam-snowflake:before {
  content: "\ebe2";
}
.jam-soundcloud:before {
  content: "\ebe3";
}
.jam-speaker:before {
  content: "\ebe5";
}
.jam-speaker-f:before {
  content: "\ebe4";
}
.jam-spotify:before {
  content: "\ebe6";
}
.jam-square:before {
  content: "\ebe8";
}
.jam-square-f:before {
  content: "\ebe7";
}
.jam-squarespace:before {
  content: "\ebeb";
}
.jam-squarespace-circle:before {
  content: "\ebe9";
}
.jam-squarespace-square:before {
  content: "\ebea";
}
.jam-stackoverflow:before {
  content: "\ebee";
}
.jam-stackoverflow-circle:before {
  content: "\ebec";
}
.jam-stackoverflow-square:before {
  content: "\ebed";
}
.jam-stamp:before {
  content: "\ebf0";
}
.jam-stamp-f:before {
  content: "\ebef";
}
.jam-star:before {
  content: "\ebf4";
}
.jam-star-f:before {
  content: "\ebf1";
}
.jam-star-full:before {
  content: "\ebf2";
}
.jam-star-half:before {
  content: "\ebf3";
}
.jam-station:before {
  content: "\ebf5";
}
.jam-stop:before {
  content: "\ebf7";
}
.jam-stop-sign:before {
  content: "\ebf6";
}
.jam-store:before {
  content: "\ebf8";
}
.jam-strikethrough:before {
  content: "\ebf9";
}
.jam-stumbleupon:before {
  content: "\ebfc";
}
.jam-stumbleupon-circle:before {
  content: "\ebfa";
}
.jam-stumbleupon-square:before {
  content: "\ebfb";
}
.jam-sun:before {
  content: "\ebfe";
}
.jam-sun-f:before {
  content: "\ebfd";
}
.jam-switch-left:before {
  content: "\ec00";
}
.jam-switch-left-f:before {
  content: "\ebff";
}
.jam-switch-right:before {
  content: "\ec02";
}
.jam-switch-right-f:before {
  content: "\ec01";
}
.jam-sword:before {
  content: "\ec04";
}
.jam-sword-f:before {
  content: "\ec03";
}
.jam-tablet:before {
  content: "\ec06";
}
.jam-tablet-f:before {
  content: "\ec05";
}
.jam-tag:before {
  content: "\ec08";
}
.jam-tag-f:before {
  content: "\ec07";
}
.jam-tags:before {
  content: "\ec0a";
}
.jam-tags-f:before {
  content: "\ec09";
}
.jam-target:before {
  content: "\ec0b";
}
.jam-task-list:before {
  content: "\ec0d";
}
.jam-task-list-f:before {
  content: "\ec0c";
}
.jam-temperature:before {
  content: "\ec0e";
}
.jam-terminal:before {
  content: "\ec0f";
}
.jam-thunder:before {
  content: "\ec11";
}
.jam-thunder-f:before {
  content: "\ec10";
}
.jam-ticket:before {
  content: "\ec13";
}
.jam-ticket-f:before {
  content: "\ec12";
}
.jam-tools:before {
  content: "\ec15";
}
.jam-tools-f:before {
  content: "\ec14";
}
.jam-torch:before {
  content: "\ec17";
}
.jam-torch-f:before {
  content: "\ec16";
}
.jam-totem:before {
  content: "\ec18";
}
.jam-train:before {
  content: "\ec1a";
}
.jam-train-f:before {
  content: "\ec19";
}
.jam-transgender:before {
  content: "\ec1b";
}
.jam-trash:before {
  content: "\ec1f";
}
.jam-trash-alt:before {
  content: "\ec1d";
}
.jam-trash-alt-f:before {
  content: "\ec1c";
}
.jam-trash-f:before {
  content: "\ec1e";
}
.jam-tree:before {
  content: "\ec23";
}
.jam-tree-alt:before {
  content: "\ec21";
}
.jam-tree-alt-f:before {
  content: "\ec20";
}
.jam-tree-f:before {
  content: "\ec22";
}
.jam-trello:before {
  content: "\ec24";
}
.jam-triangle:before {
  content: "\ec28";
}
.jam-triangle-danger:before {
  content: "\ec26";
}
.jam-triangle-danger-f:before {
  content: "\ec25";
}
.jam-triangle-f:before {
  content: "\ec27";
}
.jam-trophy:before {
  content: "\ec2a";
}
.jam-trophy-f:before {
  content: "\ec29";
}
.jam-tube:before {
  content: "\ec2b";
}
.jam-tumblr:before {
  content: "\ec2e";
}
.jam-tumblr-circle:before {
  content: "\ec2c";
}
.jam-tumblr-square:before {
  content: "\ec2d";
}
.jam-twitch:before {
  content: "\ec2f";
}
.jam-twitter:before {
  content: "\ec33";
}
.jam-twitter-circle:before {
  content: "\ec30";
}
.jam-twitter-square:before {
  content: "\ec31";
}
.jam-umbrella:before {
  content: "\ec36";
}
.jam-umbrella-closed:before {
  content: "\ec34";
}
.jam-umbrella-closed-f:before {
  content: "\ec32";
}
.jam-umbrella-f:before {
  content: "\ec35";
}
.jam-underline:before {
  content: "\ec37";
}
.jam-undo:before {
  content: "\ec38";
}
.jam-unindent:before {
  content: "\ec39";
}
.jam-universe:before {
  content: "\ec3a";
}
.jam-unordered-list:before {
  content: "\ec3b";
}
.jam-unsplash:before {
  content: "\ec3e";
}
.jam-unsplash-circle:before {
  content: "\ec3c";
}
.jam-unsplash-square:before {
  content: "\ec3d";
}
.jam-upload:before {
  content: "\ec3f";
}
.jam-user:before {
  content: "\ec46";
}
.jam-user-circle:before {
  content: "\ec40";
}
.jam-user-minus:before {
  content: "\ec41";
}
.jam-user-plus:before {
  content: "\ec42";
}
.jam-user-remove:before {
  content: "\ec43";
}
.jam-user-square:before {
  content: "\ec44";
}
.jam-users:before {
  content: "\ec45";
}
.jam-viadeo:before {
  content: "\ec47";
}
.jam-viber:before {
  content: "\ec4a";
}
.jam-viber-circle:before {
  content: "\ec48";
}
.jam-viber-square:before {
  content: "\ec49";
}
.jam-video-camera:before {
  content: "\ec4e";
}
.jam-video-camera-f:before {
  content: "\ec4b";
}
.jam-video-camera-vintage:before {
  content: "\ec4d";
}
.jam-video-camera-vintage-f:before {
  content: "\ec4c";
}
.jam-vimeo:before {
  content: "\ec52";
}
.jam-vimeo-circle:before {
  content: "\ec4f";
}
.jam-vimeo-square:before {
  content: "\ec50";
}
.jam-vine:before {
  content: "\ec54";
}
.jam-vine-circle:before {
  content: "\ec51";
}
.jam-vine-square:before {
  content: "\ec53";
}
.jam-voicemail:before {
  content: "\ec55";
}
.jam-volume:before {
  content: "\ec69";
}
.jam-volume-circle:before {
  content: "\ec57";
}
.jam-volume-circle-f:before {
  content: "\ec56";
}
.jam-volume-down:before {
  content: "\ec5c";
}
.jam-volume-down-circle:before {
  content: "\ec59";
}
.jam-volume-down-circle-f:before {
  content: "\ec58";
}
.jam-volume-down-square:before {
  content: "\ec5b";
}
.jam-volume-down-square-f:before {
  content: "\ec5a";
}
.jam-volume-mute:before {
  content: "\ec61";
}
.jam-volume-mute-circle:before {
  content: "\ec5f";
}
.jam-volume-mute-circle-f:before {
  content: "\ec5d";
}
.jam-volume-mute-square:before {
  content: "\ec60";
}
.jam-volume-mute-square-f:before {
  content: "\ec5e";
}
.jam-volume-square:before {
  content: "\ec63";
}
.jam-volume-square-f:before {
  content: "\ec62";
}
.jam-volume-up:before {
  content: "\ec68";
}
.jam-volume-up-circle:before {
  content: "\ec65";
}
.jam-volume-up-circle-f:before {
  content: "\ec64";
}
.jam-volume-up-square:before {
  content: "\ec67";
}
.jam-volume-up-square-f:before {
  content: "\ec66";
}
.jam-watch:before {
  content: "\ec6b";
}
.jam-watch-f:before {
  content: "\ec6a";
}
.jam-water-drop:before {
  content: "\ec6d";
}
.jam-water-drop-f:before {
  content: "\ec6c";
}
.jam-whatsapp:before {
  content: "\ec6e";
}
.jam-wifi:before {
  content: "\ec6f";
}
.jam-wikipedia:before {
  content: "\ec70";
}
.jam-wordpress:before {
  content: "\ec71";
}
.jam-world:before {
  content: "\ec72";
}
.jam-wrench:before {
  content: "\ec74";
}
.jam-wrench-f:before {
  content: "\ec73";
}
.jam-write:before {
  content: "\ec76";
}
.jam-write-f:before {
  content: "\ec75";
}
.jam-yahoo:before {
  content: "\ec79";
}
.jam-yahoo-circle:before {
  content: "\ec77";
}
.jam-yahoo-square:before {
  content: "\ec78";
}
.jam-yelp:before {
  content: "\ec7c";
}
.jam-yelp-circle:before {
  content: "\ec7a";
}
.jam-yelp-square:before {
  content: "\ec7b";
}
.jam-youtube:before {
  content: "\ec7f";
}
.jam-youtube-circle:before {
  content: "\ec7d";
}
.jam-youtube-square:before {
  content: "\ec7e";
}
.icofont-search-1:before
{
  content: "\ed11";
}
.icofont-search-2:before
{
  content: "\ed12";
}
.icofont-search-document:before
{
  content: "\ed13";
}
.icofont-search-folder:before
{
  content: "\ed14";
}
.icofont-search-job:before
{
  content: "\ed15";
}
.icofont-search-map:before
{
  content: "\ed16";
}
.icofont-search-property:before
{
  content: "\ed17";
}
.icofont-search-restaurant:before
{
  content: "\ed18";
}
.icofont-search-stock:before
{
  content: "\ed19";
}
.icofont-search-user:before
{
  content: "\ed1a";
}
.icofont-search:before
{
  content: "\ed1b";
}
.icofont-chart-arrows-axis:before
{
  content: "\e978";
}
.icofont-chart-bar-graph:before
{
  content: "\e979";
}
.icofont-chart-flow-1:before
{
  content: "\e97a";
}
.icofont-chart-flow-2:before
{
  content: "\e97b";
}
.icofont-chart-flow:before
{
  content: "\e97c";
}
.icofont-chart-growth:before
{
  content: "\e97d";
}
.icofont-chart-histogram-alt:before
{
  content: "\e97e";
}
.icofont-chart-histogram:before
{
  content: "\e97f";
}
.icofont-chart-line-alt:before
{
  content: "\e980";
}
.icofont-chart-line:before
{
  content: "\e981";
}
.icofont-chart-pie-alt:before
{
  content: "\e982";
}
.icofont-chart-pie:before
{
  content: "\e983";
}
.icofont-chart-radar-graph:before
{
  content: "\e984";
}
.icofont-bank-alt:before
{
  content: "\e961";
}
.icofont-bank:before
{
  content: "\e962";
}
.icofont-barcode:before
{
  content: "\e963";
}
.icofont-bill-alt:before
{
  content: "\e964";
}
.icofont-billboard:before
{
  content: "\e965";
}
.icofont-briefcase-1:before
{
  content: "\e966";
}
.icofont-briefcase-2:before
{
  content: "\e967";
}
.icofont-businessman:before
{
  content: "\e968";
}
.icofont-businesswoman:before
{
  content: "\e969";
}
.icofont-chair:before
{
  content: "\e96a";
}
.icofont-coins:before
{
  content: "\e96b";
}
.icofont-company:before
{
  content: "\e96c";
}
.icofont-contact-add:before
{
  content: "\e96d";
}
.icofont-files-stack:before
{
  content: "\e96e";
}
.icofont-handshake-deal:before
{
  content: "\e96f";
}
.icofont-id-card:before
{
  content: "\e970";
}
.icofont-meeting-add:before
{
  content: "\e971";
}
.icofont-money-bag:before
{
  content: "\e972";
}
.icofont-pie-chart:before
{
  content: "\e973";
}
.icofont-presentation-alt:before
{
  content: "\e974";
}
.icofont-presentation:before
{
  content: "\e975";
}
.icofont-stamp:before
{
  content: "\e976";
}
.icofont-stock-mobile:before
{
  content: "\e977";
}
.icofont-cassette-player:before
{
  content: "\ec99";
}
.icofont-cassette:before
{
  content: "\ec9a";
}
.icofont-forward:before
{
  content: "\ec9b";
}
.icofont-guiter:before
{
  content: "\ec9c";
}
.icofont-movie:before
{
  content: "\ec9d";
}
.icofont-multimedia:before
{
  content: "\ec9e";
}
.icofont-music-alt:before
{
  content: "\ec9f";
}
.icofont-music-disk:before
{
  content: "\eca0";
}
.icofont-music-note:before
{
  content: "\eca1";
}
.icofont-music-notes:before
{
  content: "\eca2";
}
.icofont-music:before
{
  content: "\eca3";
}
.icofont-mute-volume:before
{
  content: "\eca4";
}
.icofont-pause:before
{
  content: "\eca5";
}
.icofont-play-alt-1:before
{
  content: "\eca6";
}
.icofont-play-alt-2:before
{
  content: "\eca7";
}
.icofont-play-alt-3:before
{
  content: "\eca8";
}
.icofont-play-pause:before
{
  content: "\eca9";
}
.icofont-play:before
{
  content: "\ecaa";
}
.icofont-record:before
{
  content: "\ecab";
}
.icofont-retro-music-disk:before
{
  content: "\ecac";
}
.icofont-rewind:before
{
  content: "\ecad";
}
.icofont-song-notes:before
{
  content: "\ecae";
}
.icofont-sound-wave-alt:before
{
  content: "\ecaf";
}
.icofont-sound-wave:before
{
  content: "\ecb0";
}
.icofont-stop:before
{
  content: "\ecb1";
}
.icofont-video-alt:before
{
  content: "\ecb2";
}
.icofont-video-cam:before
{
  content: "\ecb3";
}
.icofont-video-clapper:before
{
  content: "\ecb4";
}
.icofont-video:before
{
  content: "\ecb5";
}
.icofont-volume-bar:before
{
  content: "\ecb6";
}
.icofont-volume-down:before
{
  content: "\ecb7";
}
.icofont-volume-mute:before
{
  content: "\ecb8";
}
.icofont-volume-off:before
{
  content: "\ecb9";
}
.icofont-volume-up:before
{
  content: "\ecba";
}
.icofont-youtube-play:before
{
  content: "\ecbb";
}
.icofont-boy:before
{
  content: "\eceb";
}
.icofont-business-man-alt-1:before
{
  content: "\ecec";
}
.icofont-business-man-alt-2:before
{
  content: "\eced";
}
.icofont-business-man-alt-3:before
{
  content: "\ecee";
}
.icofont-business-man:before
{
  content: "\ecef";
}
.icofont-female:before
{
  content: "\ecf0";
}
.icofont-funky-man:before
{
  content: "\ecf1";
}
.icofont-girl-alt:before
{
  content: "\ecf2";
}
.icofont-girl:before
{
  content: "\ecf3";
}
.icofont-group:before
{
  content: "\ecf4";
}
.icofont-hotel-boy-alt:before
{
  content: "\ecf5";
}
.icofont-hotel-boy:before
{
  content: "\ecf6";
}
.icofont-kid:before
{
  content: "\ecf7";
}
.icofont-man-in-glasses:before
{
  content: "\ecf8";
}
.icofont-people:before
{
  content: "\ecf9";
}
.icofont-support:before
{
  content: "\ecfa";
}
.icofont-user-alt-1:before
{
  content: "\ecfb";
}
.icofont-user-alt-2:before
{
  content: "\ecfc";
}
.icofont-user-alt-3:before
{
  content: "\ecfd";
}
.icofont-user-alt-4:before
{
  content: "\ecfe";
}
.icofont-user-alt-5:before
{
  content: "\ecff";
}
.icofont-user-alt-6:before
{
  content: "\ed00";
}
.icofont-user-alt-7:before
{
  content: "\ed01";
}
.icofont-user-female:before
{
  content: "\ed02";
}
.icofont-user-male:before
{
  content: "\ed03";
}
.icofont-user-suited:before
{
  content: "\ed04";
}
.icofont-user:before
{
  content: "\ed05";
}
.icofont-users-alt-1:before
{
  content: "\ed06";
}
.icofont-users-alt-2:before
{
  content: "\ed07";
}
.icofont-users-alt-3:before
{
  content: "\ed08";
}
.icofont-users-alt-4:before
{
  content: "\ed09";
}
.icofont-users-alt-5:before
{
  content: "\ed0a";
}
.icofont-users-alt-6:before
{
  content: "\ed0b";
}
.icofont-users-social:before
{
  content: "\ed0c";
}
.icofont-users:before
{
  content: "\ed0d";
}
.icofont-waiter-alt:before
{
  content: "\ed0e";
}
.icofont-waiter:before
{
  content: "\ed0f";
}
.icofont-woman-in-glasses:before
{
  content: "\ed10";
}
.icofont-abc:before
{
  content: "\eacc";
}
.icofont-atom:before
{
  content: "\eacd";
}
.icofont-award:before
{
  content: "\eace";
}
.icofont-bell-alt:before
{
  content: "\eacf";
}
.icofont-black-board:before
{
  content: "\ead0";
}
.icofont-book-alt:before
{
  content: "\ead1";
}
.icofont-book:before
{
  content: "\ead2";
}
.icofont-brainstorming:before
{
  content: "\ead3";
}
.icofont-certificate-alt-1:before
{
  content: "\ead4";
}
.icofont-certificate-alt-2:before
{
  content: "\ead5";
}
.icofont-certificate:before
{
  content: "\ead6";
}
.icofont-education:before
{
  content: "\ead7";
}
.icofont-electron:before
{
  content: "\ead8";
}
.icofont-fountain-pen:before
{
  content: "\ead9";
}
.icofont-globe-alt:before
{
  content: "\eada";
}
.icofont-graduate-alt:before
{
  content: "\eadb";
}
.icofont-graduate:before
{
  content: "\eadc";
}
.icofont-group-students:before
{
  content: "\eadd";
}
.icofont-hat-alt:before
{
  content: "\eade";
}
.icofont-hat:before
{
  content: "\eadf";
}
.icofont-instrument:before
{
  content: "\eae0";
}
.icofont-lamp-light:before
{
  content: "\eae1";
}
.icofont-medal:before
{
  content: "\eae2";
}
.icofont-microscope-alt:before
{
  content: "\eae3";
}
.icofont-microscope:before
{
  content: "\eae4";
}
.icofont-paper:before
{
  content: "\eae5";
}
.icofont-pen-alt-4:before
{
  content: "\eae6";
}
.icofont-pen-nib:before
{
  content: "\eae7";
}
.icofont-pencil-alt-5:before
{
  content: "\eae8";
}
.icofont-quill-pen:before
{
  content: "\eae9";
}
.icofont-read-book-alt:before
{
  content: "\eaea";
}
.icofont-read-book:before
{
  content: "\eaeb";
}
.icofont-school-bag:before
{
  content: "\eaec";
}
.icofont-school-bus:before
{
  content: "\eaed";
}
.icofont-student-alt:before
{
  content: "\eaee";
}
.icofont-student:before
{
  content: "\eaef";
}
.icofont-teacher:before
{
  content: "\eaf0";
}
.icofont-test-bulb:before
{
  content: "\eaf1";
}
.icofont-test-tube-alt:before
{
  content: "\eaf2";
}
.icofont-university:before
{
  content: "\eaf3";
}
.icofont-angry-monster:before
{
  content: "\e800";
}
.icofont-bathtub:before
{
  content: "\e801";
}
.icofont-bird-wings:before
{
  content: "\e802";
}
.icofont-bow:before
{
  content: "\e803";
}
.icofont-castle:before
{
  content: "\e804";
}
.icofont-circuit:before
{
  content: "\e805";
}
.icofont-crown-king:before
{
  content: "\e806";
}
.icofont-crown-queen:before
{
  content: "\e807";
}
.icofont-dart:before
{
  content: "\e808";
}
.icofont-disability-race:before
{
  content: "\e809";
}
.icofont-diving-goggle:before
{
  content: "\e80a";
}
.icofont-eye-open:before
{
  content: "\e80b";
}
.icofont-flora-flower:before
{
  content: "\e80c";
}
.icofont-flora:before
{
  content: "\e80d";
}
.icofont-gift-box:before
{
  content: "\e80e";
}
.icofont-halloween-pumpkin:before
{
  content: "\e80f";
}
.icofont-hand-power:before
{
  content: "\e810";
}
.icofont-hand-thunder:before
{
  content: "\e811";
}
.icofont-king-monster:before
{
  content: "\e812";
}
.icofont-love:before
{
  content: "\e813";
}
.icofont-magician-hat:before
{
  content: "\e814";
}
.icofont-native-american:before
{
  content: "\e815";
}
.icofont-owl-look:before
{
  content: "\e816";
}
.icofont-phoenix:before
{
  content: "\e817";
}
.icofont-robot-face:before
{
  content: "\e818";
}
.icofont-sand-clock:before
{
  content: "\e819";
}
.icofont-shield-alt:before
{
  content: "\e81a";
}
.icofont-ship-wheel:before
{
  content: "\e81b";
}
.icofont-skull-danger:before
{
  content: "\e81c";
}
.icofont-skull-face:before
{
  content: "\e81d";
}
.icofont-snowmobile:before
{
  content: "\e81e";
}
.icofont-space-shuttle:before
{
  content: "\e81f";
}
.icofont-star-shape:before
{
  content: "\e820";
}
.icofont-swirl:before
{
  content: "\e821";
}
.icofont-tattoo-wing:before
{
  content: "\e822";
}
.icofont-throne:before
{
  content: "\e823";
}
.icofont-tree-alt:before
{
  content: "\e824";
}
.icofont-triangle:before
{
  content: "\e825";
}
.icofont-unity-hand:before
{
  content: "\e826";
}
.icofont-weed:before
{
  content: "\e827";
}
.icofont-woman-bird:before
{
  content: "\e828";
}
.icofont-align-center:before
{
  content: "\eddf";
}
.icofont-align-left:before
{
  content: "\ede0";
}
.icofont-align-right:before
{
  content: "\ede1";
}
.icofont-all-caps:before
{
  content: "\ede2";
}
.icofont-bold:before
{
  content: "\ede3";
}
.icofont-brush:before
{
  content: "\ede4";
}
.icofont-clip-board:before
{
  content: "\ede5";
}
.icofont-code-alt:before
{
  content: "\ede6";
}
.icofont-color-bucket:before
{
  content: "\ede7";
}
.icofont-color-picker:before
{
  content: "\ede8";
}
.icofont-copy-invert:before
{
  content: "\ede9";
}
.icofont-copy:before
{
  content: "\edea";
}
.icofont-cut:before
{
  content: "\edeb";
}
.icofont-delete-alt:before
{
  content: "\edec";
}
.icofont-edit-alt:before
{
  content: "\eded";
}
.icofont-eraser-alt:before
{
  content: "\edee";
}
.icofont-font:before
{
  content: "\edef";
}
.icofont-heading:before
{
  content: "\edf0";
}
.icofont-indent:before
{
  content: "\edf1";
}
.icofont-italic-alt:before
{
  content: "\edf2";
}
.icofont-italic:before
{
  content: "\edf3";
}
.icofont-justify-all:before
{
  content: "\edf4";
}
.icofont-justify-center:before
{
  content: "\edf5";
}
.icofont-justify-left:before
{
  content: "\edf6";
}
.icofont-justify-right:before
{
  content: "\edf7";
}
.icofont-link-broken:before
{
  content: "\edf8";
}
.icofont-outdent:before
{
  content: "\edf9";
}
.icofont-paper-clip:before
{
  content: "\edfa";
}
.icofont-paragraph:before
{
  content: "\edfb";
}
.icofont-pin:before
{
  content: "\edfc";
}
.icofont-printer:before
{
  content: "\edfd";
}
.icofont-redo:before
{
  content: "\edfe";
}
.icofont-rotation:before
{
  content: "\edff";
}
.icofont-save:before
{
  content: "\ee00";
}
.icofont-small-cap:before
{
  content: "\ee01";
}
.icofont-strike-through:before
{
  content: "\ee02";
}
.icofont-sub-listing:before
{
  content: "\ee03";
}
.icofont-subscript:before
{
  content: "\ee04";
}
.icofont-superscript:before
{
  content: "\ee05";
}
.icofont-table:before
{
  content: "\ee06";
}
.icofont-text-height:before
{
  content: "\ee07";
}
.icofont-text-width:before
{
  content: "\ee08";
}
.icofont-trash:before
{
  content: "\ee09";
}
.icofont-underline:before
{
  content: "\ee0a";
}
.icofont-undo:before
{
  content: "\ee0b";
}
.icofont-abacus-alt:before
{
  content: "\ebd7";
}
.icofont-abacus:before
{
  content: "\ebd8";
}
.icofont-angle-180:before
{
  content: "\ebd9";
}
.icofont-angle-45:before
{
  content: "\ebda";
}
.icofont-angle-90:before
{
  content: "\ebdb";
}
.icofont-angle:before
{
  content: "\ebdc";
}
.icofont-calculator-alt-1:before
{
  content: "\ebdd";
}
.icofont-calculator-alt-2:before
{
  content: "\ebde";
}
.icofont-calculator:before
{
  content: "\ebdf";
}
.icofont-circle-ruler-alt:before
{
  content: "\ebe0";
}
.icofont-circle-ruler:before
{
  content: "\ebe1";
}
.icofont-compass-alt-1:before
{
  content: "\ebe2";
}
.icofont-compass-alt-2:before
{
  content: "\ebe3";
}
.icofont-compass-alt-3:before
{
  content: "\ebe4";
}
.icofont-compass-alt-4:before
{
  content: "\ebe5";
}
.icofont-golden-ratio:before
{
  content: "\ebe6";
}
.icofont-marker-alt-1:before
{
  content: "\ebe7";
}
.icofont-marker-alt-2:before
{
  content: "\ebe8";
}
.icofont-marker-alt-3:before
{
  content: "\ebe9";
}
.icofont-marker:before
{
  content: "\ebea";
}
.icofont-math:before
{
  content: "\ebeb";
}
.icofont-mathematical-alt-1:before
{
  content: "\ebec";
}
.icofont-mathematical-alt-2:before
{
  content: "\ebed";
}
.icofont-mathematical:before
{
  content: "\ebee";
}
.icofont-pen-alt-1:before
{
  content: "\ebef";
}
.icofont-pen-alt-2:before
{
  content: "\ebf0";
}
.icofont-pen-alt-3:before
{
  content: "\ebf1";
}
.icofont-pen-holder-alt-1:before
{
  content: "\ebf2";
}
.icofont-pen-holder:before
{
  content: "\ebf3";
}
.icofont-pen:before
{
  content: "\ebf4";
}
.icofont-pencil-alt-1:before
{
  content: "\ebf5";
}
.icofont-pencil-alt-2:before
{
  content: "\ebf6";
}
.icofont-pencil-alt-3:before
{
  content: "\ebf7";
}
.icofont-pencil-alt-4:before
{
  content: "\ebf8";
}
.icofont-pencil:before
{
  content: "\ebf9";
}
.icofont-ruler-alt-1:before
{
  content: "\ebfa";
}
.icofont-ruler-alt-2:before
{
  content: "\ebfb";
}
.icofont-ruler-compass-alt:before
{
  content: "\ebfc";
}
.icofont-ruler-compass:before
{
  content: "\ebfd";
}
.icofont-ruler-pencil-alt-1:before
{
  content: "\ebfe";
}
.icofont-ruler-pencil-alt-2:before
{
  content: "\ebff";
}
.icofont-ruler-pencil:before
{
  content: "\ec00";
}
.icofont-ruler:before
{
  content: "\ec01";
}
.icofont-rulers-alt:before
{
  content: "\ec02";
}
.icofont-rulers:before
{
  content: "\ec03";
}
.icofont-square-root:before
{
  content: "\ec04";
}
.icofont-ui-calculator:before
{
  content: "\ec05";
}
.icofont-2checkout-alt:before
{
  content: "\ecbc";
}
.icofont-2checkout:before
{
  content: "\ecbd";
}
.icofont-amazon-alt:before
{
  content: "\ecbe";
}
.icofont-amazon:before
{
  content: "\ecbf";
}
.icofont-american-express-alt:before
{
  content: "\ecc0";
}
.icofont-american-express:before
{
  content: "\ecc1";
}
.icofont-apple-pay-alt:before
{
  content: "\ecc2";
}
.icofont-apple-pay:before
{
  content: "\ecc3";
}
.icofont-bank-transfer-alt:before
{
  content: "\ecc4";
}
.icofont-bank-transfer:before
{
  content: "\ecc5";
}
.icofont-braintree-alt:before
{
  content: "\ecc6";
}
.icofont-braintree:before
{
  content: "\ecc7";
}
.icofont-cash-on-delivery-alt:before
{
  content: "\ecc8";
}
.icofont-cash-on-delivery:before
{
  content: "\ecc9";
}
.icofont-diners-club-alt-1:before
{
  content: "\ecca";
}
.icofont-diners-club-alt-2:before
{
  content: "\eccb";
}
.icofont-diners-club-alt-3:before
{
  content: "\eccc";
}
.icofont-diners-club:before
{
  content: "\eccd";
}
.icofont-discover-alt:before
{
  content: "\ecce";
}
.icofont-discover:before
{
  content: "\eccf";
}
.icofont-eway-alt:before
{
  content: "\ecd0";
}
.icofont-eway:before
{
  content: "\ecd1";
}
.icofont-google-wallet-alt-1:before
{
  content: "\ecd2";
}
.icofont-google-wallet-alt-2:before
{
  content: "\ecd3";
}
.icofont-google-wallet-alt-3:before
{
  content: "\ecd4";
}
.icofont-google-wallet:before
{
  content: "\ecd5";
}
.icofont-jcb-alt:before
{
  content: "\ecd6";
}
.icofont-jcb:before
{
  content: "\ecd7";
}
.icofont-maestro-alt:before
{
  content: "\ecd8";
}
.icofont-maestro:before
{
  content: "\ecd9";
}
.icofont-mastercard-alt:before
{
  content: "\ecda";
}
.icofont-mastercard:before
{
  content: "\ecdb";
}
.icofont-payoneer-alt:before
{
  content: "\ecdc";
}
.icofont-payoneer:before
{
  content: "\ecdd";
}
.icofont-paypal-alt:before
{
  content: "\ecde";
}
.icofont-paypal:before
{
  content: "\ecdf";
}
.icofont-sage-alt:before
{
  content: "\ece0";
}
.icofont-sage:before
{
  content: "\ece1";
}
.icofont-skrill-alt:before
{
  content: "\ece2";
}
.icofont-skrill:before
{
  content: "\ece3";
}
.icofont-stripe-alt:before
{
  content: "\ece4";
}
.icofont-stripe:before
{
  content: "\ece5";
}
.icofont-visa-alt:before
{
  content: "\ece6";
}
.icofont-visa-electron:before
{
  content: "\ece7";
}
.icofont-visa:before
{
  content: "\ece8";
}
.icofont-western-union-alt:before
{
  content: "\ece9";
}
.icofont-western-union:before
{
  content: "\ecea";
}
.icofont-android-nexus:before
{
  content: "\ea29";
}
.icofont-android-tablet:before
{
  content: "\ea2a";
}
.icofont-apple-watch:before
{
  content: "\ea2b";
}
.icofont-drawing-tablet:before
{
  content: "\ea2c";
}
.icofont-earphone:before
{
  content: "\ea2d";
}
.icofont-flash-drive:before
{
  content: "\ea2e";
}
.icofont-game-console:before
{
  content: "\ea2f";
}
.icofont-game-controller:before
{
  content: "\ea30";
}
.icofont-game-pad:before
{
  content: "\ea31";
}
.icofont-game:before
{
  content: "\ea32";
}
.icofont-headphone-alt-1:before
{
  content: "\ea33";
}
.icofont-headphone-alt-2:before
{
  content: "\ea34";
}
.icofont-headphone-alt-3:before
{
  content: "\ea35";
}
.icofont-headphone-alt:before
{
  content: "\ea36";
}
.icofont-headphone:before
{
  content: "\ea37";
}
.icofont-htc-one:before
{
  content: "\ea38";
}
.icofont-imac:before
{
  content: "\ea39";
}
.icofont-ipad:before
{
  content: "\ea3a";
}
.icofont-iphone:before
{
  content: "\ea3b";
}
.icofont-ipod-nano:before
{
  content: "\ea3c";
}
.icofont-ipod-touch:before
{
  content: "\ea3d";
}
.icofont-keyboard-alt:before
{
  content: "\ea3e";
}
.icofont-keyboard-wireless:before
{
  content: "\ea3f";
}
.icofont-keyboard:before
{
  content: "\ea40";
}
.icofont-laptop-alt:before
{
  content: "\ea41";
}
.icofont-laptop:before
{
  content: "\ea42";
}
.icofont-macbook:before
{
  content: "\ea43";
}
.icofont-magic-mouse:before
{
  content: "\ea44";
}
.icofont-micro-chip:before
{
  content: "\ea45";
}
.icofont-microphone-alt:before
{
  content: "\ea46";
}
.icofont-microphone:before
{
  content: "\ea47";
}
.icofont-monitor:before
{
  content: "\ea48";
}
.icofont-mouse:before
{
  content: "\ea49";
}
.icofont-mp3-player:before
{
  content: "\ea4a";
}
.icofont-nintendo:before
{
  content: "\ea4b";
}
.icofont-playstation-alt:before
{
  content: "\ea4c";
}
.icofont-psvita:before
{
  content: "\ea4d";
}
.icofont-radio-mic:before
{
  content: "\ea4e";
}
.icofont-radio:before
{
  content: "\ea4f";
}
.icofont-refrigerator:before
{
  content: "\ea50";
}
.icofont-samsung-galaxy:before
{
  content: "\ea51";
}
.icofont-surface-tablet:before
{
  content: "\ea52";
}
.icofont-ui-head-phone:before
{
  content: "\ea53";
}
.icofont-ui-keyboard:before
{
  content: "\ea54";
}
.icofont-washing-machine:before
{
  content: "\ea55";
}
.icofont-wifi-router:before
{
  content: "\ea56";
}
.icofont-wii-u:before
{
  content: "\ea57";
}
.icofont-windows-lumia:before
{
  content: "\ea58";
}
.icofont-wireless-mouse:before
{
  content: "\ea59";
}
.icofont-xbox-360:before
{
  content: "\ea5a";
}
.icofont-air-balloon:before
{
  content: "\ee0c";
}
.icofont-airplane-alt:before
{
  content: "\ee0d";
}
.icofont-airplane:before
{
  content: "\ee0e";
}
.icofont-articulated-truck:before
{
  content: "\ee0f";
}
.icofont-auto-mobile:before
{
  content: "\ee10";
}
.icofont-auto-rickshaw:before
{
  content: "\ee11";
}
.icofont-bicycle-alt-1:before
{
  content: "\ee12";
}
.icofont-bicycle-alt-2:before
{
  content: "\ee13";
}
.icofont-bicycle:before
{
  content: "\ee14";
}
.icofont-bus-alt-1:before
{
  content: "\ee15";
}
.icofont-bus-alt-2:before
{
  content: "\ee16";
}
.icofont-bus-alt-3:before
{
  content: "\ee17";
}
.icofont-bus:before
{
  content: "\ee18";
}
.icofont-cab:before
{
  content: "\ee19";
}
.icofont-cable-car:before
{
  content: "\ee1a";
}
.icofont-car-alt-1:before
{
  content: "\ee1b";
}
.icofont-car-alt-2:before
{
  content: "\ee1c";
}
.icofont-car-alt-3:before
{
  content: "\ee1d";
}
.icofont-car-alt-4:before
{
  content: "\ee1e";
}
.icofont-car:before
{
  content: "\ee1f";
}
.icofont-delivery-time:before
{
  content: "\ee20";
}
.icofont-fast-delivery:before
{
  content: "\ee21";
}
.icofont-fire-truck-alt:before
{
  content: "\ee22";
}
.icofont-fire-truck:before
{
  content: "\ee23";
}
.icofont-free-delivery:before
{
  content: "\ee24";
}
.icofont-helicopter:before
{
  content: "\ee25";
}
.icofont-motor-bike-alt:before
{
  content: "\ee26";
}
.icofont-motor-bike:before
{
  content: "\ee27";
}
.icofont-motor-biker:before
{
  content: "\ee28";
}
.icofont-oil-truck:before
{
  content: "\ee29";
}
.icofont-rickshaw:before
{
  content: "\ee2a";
}
.icofont-rocket-alt-1:before
{
  content: "\ee2b";
}
.icofont-rocket-alt-2:before
{
  content: "\ee2c";
}
.icofont-rocket:before
{
  content: "\ee2d";
}
.icofont-sail-boat-alt-1:before
{
  content: "\ee2e";
}
.icofont-sail-boat-alt-2:before
{
  content: "\ee2f";
}
.icofont-sail-boat:before
{
  content: "\ee30";
}
.icofont-scooter:before
{
  content: "\ee31";
}
.icofont-sea-plane:before
{
  content: "\ee32";
}
.icofont-ship-alt:before
{
  content: "\ee33";
}
.icofont-ship:before
{
  content: "\ee34";
}
.icofont-speed-boat:before
{
  content: "\ee35";
}
.icofont-taxi:before
{
  content: "\ee36";
}
.icofont-tractor:before
{
  content: "\ee37";
}
.icofont-train-line:before
{
  content: "\ee38";
}
.icofont-train-steam:before
{
  content: "\ee39";
}
.icofont-tram:before
{
  content: "\ee3a";
}
.icofont-truck-alt:before
{
  content: "\ee3b";
}
.icofont-truck-loaded:before
{
  content: "\ee3c";
}
.icofont-truck:before
{
  content: "\ee3d";
}
.icofont-van-alt:before
{
  content: "\ee3e";
}
.icofont-van:before
{
  content: "\ee3f";
}
.icofont-yacht:before
{
  content: "\ee40";
}
.icofont-aids:before
{
  content: "\ec06";
}
.icofont-ambulance-crescent:before
{
  content: "\ec07";
}
.icofont-ambulance-cross:before
{
  content: "\ec08";
}
.icofont-ambulance:before
{
  content: "\ec09";
}
.icofont-autism:before
{
  content: "\ec0a";
}
.icofont-bandage:before
{
  content: "\ec0b";
}
.icofont-blind:before
{
  content: "\ec0c";
}
.icofont-blood-drop:before
{
  content: "\ec0d";
}
.icofont-blood-test:before
{
  content: "\ec0e";
}
.icofont-blood:before
{
  content: "\ec0f";
}
.icofont-brain-alt:before
{
  content: "\ec10";
}
.icofont-brain:before
{
  content: "\ec11";
}
.icofont-capsule:before
{
  content: "\ec12";
}
.icofont-crutch:before
{
  content: "\ec13";
}
.icofont-disabled:before
{
  content: "\ec14";
}
.icofont-dna-alt-1:before
{
  content: "\ec15";
}
.icofont-dna-alt-2:before
{
  content: "\ec16";
}
.icofont-dna:before
{
  content: "\ec17";
}
.icofont-doctor-alt:before
{
  content: "\ec18";
}
.icofont-doctor:before
{
  content: "\ec19";
}
.icofont-drug-pack:before
{
  content: "\ec1a";
}
.icofont-drug:before
{
  content: "\ec1b";
}
.icofont-first-aid-alt:before
{
  content: "\ec1c";
}
.icofont-first-aid:before
{
  content: "\ec1d";
}
.icofont-heart-beat-alt:before
{
  content: "\ec1e";
}
.icofont-heart-beat:before
{
  content: "\ec1f";
}
.icofont-heartbeat:before
{
  content: "\ec20";
}
.icofont-herbal:before
{
  content: "\ec21";
}
.icofont-hospital:before
{
  content: "\ec22";
}
.icofont-icu:before
{
  content: "\ec23";
}
.icofont-injection-syringe:before
{
  content: "\ec24";
}
.icofont-laboratory:before
{
  content: "\ec25";
}
.icofont-medical-sign-alt:before
{
  content: "\ec26";
}
.icofont-medical-sign:before
{
  content: "\ec27";
}
.icofont-nurse-alt:before
{
  content: "\ec28";
}
.icofont-nurse:before
{
  content: "\ec29";
}
.icofont-nursing-home:before
{
  content: "\ec2a";
}
.icofont-operation-theater:before
{
  content: "\ec2b";
}
.icofont-paralysis-disability:before
{
  content: "\ec2c";
}
.icofont-patient-bed:before
{
  content: "\ec2d";
}
.icofont-patient-file:before
{
  content: "\ec2e";
}
.icofont-pills:before
{
  content: "\ec2f";
}
.icofont-prescription:before
{
  content: "\ec30";
}
.icofont-pulse:before
{
  content: "\ec31";
}
.icofont-stethoscope-alt:before
{
  content: "\ec32";
}
.icofont-stethoscope:before
{
  content: "\ec33";
}
.icofont-stretcher:before
{
  content: "\ec34";
}
.icofont-surgeon-alt:before
{
  content: "\ec35";
}
.icofont-surgeon:before
{
  content: "\ec36";
}
.icofont-tablets:before
{
  content: "\ec37";
}
.icofont-test-bottle:before
{
  content: "\ec38";
}
.icofont-test-tube:before
{
  content: "\ec39";
}
.icofont-thermometer-alt:before
{
  content: "\ec3a";
}
.icofont-thermometer:before
{
  content: "\ec3b";
}
.icofont-tooth:before
{
  content: "\ec3c";
}
.icofont-xray:before
{
  content: "\ec3d";
}
.icofont-architecture-alt:before
{
  content: "\e985";
}
.icofont-architecture:before
{
  content: "\e986";
}
.icofont-barricade:before
{
  content: "\e987";
}
.icofont-bolt:before
{
  content: "\e988";
}
.icofont-bricks:before
{
  content: "\e989";
}
.icofont-building-alt:before
{
  content: "\e98a";
}
.icofont-bull-dozer:before
{
  content: "\e98b";
}
.icofont-calculations:before
{
  content: "\e98c";
}
.icofont-cement-mix:before
{
  content: "\e98d";
}
.icofont-cement-mixer:before
{
  content: "\e98e";
}
.icofont-concrete-mixer:before
{
  content: "\e98f";
}
.icofont-danger-zone:before
{
  content: "\e990";
}
.icofont-drill:before
{
  content: "\e991";
}
.icofont-eco-energy:before
{
  content: "\e992";
}
.icofont-eco-environmen:before
{
  content: "\e993";
}
.icofont-energy-air:before
{
  content: "\e994";
}
.icofont-energy-oil:before
{
  content: "\e995";
}
.icofont-energy-savings:before
{
  content: "\e996";
}
.icofont-energy-solar:before
{
  content: "\e997";
}
.icofont-energy-water:before
{
  content: "\e998";
}
.icofont-engineer:before
{
  content: "\e999";
}
.icofont-fire-extinguisher-alt:before
{
  content: "\e99a";
}
.icofont-fire-extinguisher:before
{
  content: "\e99b";
}
.icofont-fix-tools:before
{
  content: "\e99c";
}
.icofont-fork-lift:before
{
  content: "\e99d";
}
.icofont-glue-oil:before
{
  content: "\e99e";
}
.icofont-hammer-alt:before
{
  content: "\e99f";
}
.icofont-hammer:before
{
  content: "\e9a0";
}
.icofont-help-robot:before
{
  content: "\e9a1";
}
.icofont-industries-1:before
{
  content: "\e9a2";
}
.icofont-industries-2:before
{
  content: "\e9a3";
}
.icofont-industries-3:before
{
  content: "\e9a4";
}
.icofont-industries-4:before
{
  content: "\e9a5";
}
.icofont-industries-5:before
{
  content: "\e9a6";
}
.icofont-industries:before
{
  content: "\e9a7";
}
.icofont-labour:before
{
  content: "\e9a8";
}
.icofont-mining:before
{
  content: "\e9a9";
}
.icofont-paint-brush:before
{
  content: "\e9aa";
}
.icofont-pollution:before
{
  content: "\e9ab";
}
.icofont-power-zone:before
{
  content: "\e9ac";
}
.icofont-radio-active:before
{
  content: "\e9ad";
}
.icofont-recycle-alt:before
{
  content: "\e9ae";
}
.icofont-recycling-man:before
{
  content: "\e9af";
}
.icofont-safety-hat-light:before
{
  content: "\e9b0";
}
.icofont-safety-hat:before
{
  content: "\e9b1";
}
.icofont-saw:before
{
  content: "\e9b2";
}
.icofont-screw-driver:before
{
  content: "\e9b3";
}
.icofont-tools-1:before
{
  content: "\e9b4";
}
.icofont-tools-bag:before
{
  content: "\e9b5";
}
.icofont-tow-truck:before
{
  content: "\e9b6";
}
.icofont-trolley:before
{
  content: "\e9b7";
}
.icofont-trowel:before
{
  content: "\e9b8";
}
.icofont-under-construction-alt:before
{
  content: "\e9b9";
}
.icofont-under-construction:before
{
  content: "\e9ba";
}
.icofont-vehicle-cement:before
{
  content: "\e9bb";
}
.icofont-vehicle-crane:before
{
  content: "\e9bc";
}
.icofont-vehicle-delivery-van:before
{
  content: "\e9bd";
}
.icofont-vehicle-dozer:before
{
  content: "\e9be";
}
.icofont-vehicle-excavator:before
{
  content: "\e9bf";
}
.icofont-vehicle-trucktor:before
{
  content: "\e9c0";
}
.icofont-vehicle-wrecking:before
{
  content: "\e9c1";
}
.icofont-worker:before
{
  content: "\e9c2";
}
.icofont-workers-group:before
{
  content: "\e9c3";
}
.icofont-wrench:before
{
  content: "\e9c4";
}
.icofont-breakdown:before
{
  content: "\ee4f";
}
.icofont-celsius:before
{
  content: "\ee50";
}
.icofont-clouds:before
{
  content: "\ee51";
}
.icofont-cloudy:before
{
  content: "\ee52";
}
.icofont-dust:before
{
  content: "\ee53";
}
.icofont-eclipse:before
{
  content: "\ee54";
}
.icofont-fahrenheit:before
{
  content: "\ee55";
}
.icofont-forest-fire:before
{
  content: "\ee56";
}
.icofont-full-night:before
{
  content: "\ee57";
}
.icofont-full-sunny:before
{
  content: "\ee58";
}
.icofont-hail-night:before
{
  content: "\ee59";
}
.icofont-hail-rainy-night:before
{
  content: "\ee5a";
}
.icofont-hail-rainy-sunny:before
{
  content: "\ee5b";
}
.icofont-hail-rainy:before
{
  content: "\ee5c";
}
.icofont-hail-sunny:before
{
  content: "\ee5d";
}
.icofont-hail-thunder-night:before
{
  content: "\ee5e";
}
.icofont-hail-thunder-sunny:before
{
  content: "\ee5f";
}
.icofont-hail-thunder:before
{
  content: "\ee60";
}
.icofont-hail:before
{
  content: "\ee61";
}
.icofont-hill-night:before
{
  content: "\ee62";
}
.icofont-hill-sunny:before
{
  content: "\ee63";
}
.icofont-hurricane:before
{
  content: "\ee64";
}
.icofont-meteor:before
{
  content: "\ee65";
}
.icofont-night:before
{
  content: "\ee66";
}
.icofont-rainy-night:before
{
  content: "\ee67";
}
.icofont-rainy-sunny:before
{
  content: "\ee68";
}
.icofont-rainy-thunder:before
{
  content: "\ee69";
}
.icofont-rainy:before
{
  content: "\ee6a";
}
.icofont-snow-alt:before
{
  content: "\ee6b";
}
.icofont-snow-flake:before
{
  content: "\ee6c";
}
.icofont-snow-temp:before
{
  content: "\ee6d";
}
.icofont-snow:before
{
  content: "\ee6e";
}
.icofont-snowy-hail:before
{
  content: "\ee6f";
}
.icofont-snowy-night-hail:before
{
  content: "\ee70";
}
.icofont-snowy-night-rainy:before
{
  content: "\ee71";
}
.icofont-snowy-night:before
{
  content: "\ee72";
}
.icofont-snowy-rainy:before
{
  content: "\ee73";
}
.icofont-snowy-sunny-hail:before
{
  content: "\ee74";
}
.icofont-snowy-sunny-rainy:before
{
  content: "\ee75";
}
.icofont-snowy-sunny:before
{
  content: "\ee76";
}
.icofont-snowy-thunder-night:before
{
  content: "\ee77";
}
.icofont-snowy-thunder-sunny:before
{
  content: "\ee78";
}
.icofont-snowy-thunder:before
{
  content: "\ee79";
}
.icofont-snowy-windy-night:before
{
  content: "\ee7a";
}
.icofont-snowy-windy-sunny:before
{
  content: "\ee7b";
}
.icofont-snowy-windy:before
{
  content: "\ee7c";
}
.icofont-snowy:before
{
  content: "\ee7d";
}
.icofont-sun-alt:before
{
  content: "\ee7e";
}
.icofont-sun-rise:before
{
  content: "\ee7f";
}
.icofont-sun-set:before
{
  content: "\ee80";
}
.icofont-sun:before
{
  content: "\ee81";
}
.icofont-sunny-day-temp:before
{
  content: "\ee82";
}
.icofont-sunny:before
{
  content: "\ee83";
}
.icofont-thunder-light:before
{
  content: "\ee84";
}
.icofont-tornado:before
{
  content: "\ee85";
}
.icofont-umbrella-alt:before
{
  content: "\ee86";
}
.icofont-umbrella:before
{
  content: "\ee87";
}
.icofont-volcano:before
{
  content: "\ee88";
}
.icofont-wave:before
{
  content: "\ee89";
}
.icofont-wind-scale-0:before
{
  content: "\ee8a";
}
.icofont-wind-scale-1:before
{
  content: "\ee8b";
}
.icofont-wind-scale-10:before
{
  content: "\ee8c";
}
.icofont-wind-scale-11:before
{
  content: "\ee8d";
}
.icofont-wind-scale-12:before
{
  content: "\ee8e";
}
.icofont-wind-scale-2:before
{
  content: "\ee8f";
}
.icofont-wind-scale-3:before
{
  content: "\ee90";
}
.icofont-wind-scale-4:before
{
  content: "\ee91";
}
.icofont-wind-scale-5:before
{
  content: "\ee92";
}
.icofont-wind-scale-6:before
{
  content: "\ee93";
}
.icofont-wind-scale-7:before
{
  content: "\ee94";
}
.icofont-wind-scale-8:before
{
  content: "\ee95";
}
.icofont-wind-scale-9:before
{
  content: "\ee96";
}
.icofont-wind-waves:before
{
  content: "\ee97";
}
.icofont-wind:before
{
  content: "\ee98";
}
.icofont-windy-hail:before
{
  content: "\ee99";
}
.icofont-windy-night:before
{
  content: "\ee9a";
}
.icofont-windy-raining:before
{
  content: "\ee9b";
}
.icofont-windy-sunny:before
{
  content: "\ee9c";
}
.icofont-windy-thunder-raining:before
{
  content: "\ee9d";
}
.icofont-windy-thunder:before
{
  content: "\ee9e";
}
.icofont-windy:before
{
  content: "\ee9f";
}
.icofont-badminton-birdie:before
{
  content: "\ed8d";
}
.icofont-baseball:before
{
  content: "\ed8e";
}
.icofont-baseballer:before
{
  content: "\ed8f";
}
.icofont-basketball-hoop:before
{
  content: "\ed90";
}
.icofont-basketball:before
{
  content: "\ed91";
}
.icofont-billiard-ball:before
{
  content: "\ed92";
}
.icofont-boot-alt-1:before
{
  content: "\ed93";
}
.icofont-boot-alt-2:before
{
  content: "\ed94";
}
.icofont-boot:before
{
  content: "\ed95";
}
.icofont-bowling-alt:before
{
  content: "\ed96";
}
.icofont-bowling:before
{
  content: "\ed97";
}
.icofont-canoe:before
{
  content: "\ed98";
}
.icofont-cheer-leader:before
{
  content: "\ed99";
}
.icofont-climbing:before
{
  content: "\ed9a";
}
.icofont-corner:before
{
  content: "\ed9b";
}
.icofont-field-alt:before
{
  content: "\ed9c";
}
.icofont-field:before
{
  content: "\ed9d";
}
.icofont-football-alt:before
{
  content: "\ed9e";
}
.icofont-football-american:before
{
  content: "\ed9f";
}
.icofont-football:before
{
  content: "\eda0";
}
.icofont-foul:before
{
  content: "\eda1";
}
.icofont-goal-keeper:before
{
  content: "\eda2";
}
.icofont-goal:before
{
  content: "\eda3";
}
.icofont-golf-alt:before
{
  content: "\eda4";
}
.icofont-golf-bag:before
{
  content: "\eda5";
}
.icofont-golf-cart:before
{
  content: "\eda6";
}
.icofont-golf-field:before
{
  content: "\eda7";
}
.icofont-golf:before
{
  content: "\eda8";
}
.icofont-golfer:before
{
  content: "\eda9";
}
.icofont-helmet:before
{
  content: "\edaa";
}
.icofont-hockey-alt:before
{
  content: "\edab";
}
.icofont-hockey:before
{
  content: "\edac";
}
.icofont-ice-skate:before
{
  content: "\edad";
}
.icofont-jersey-alt:before
{
  content: "\edae";
}
.icofont-jersey:before
{
  content: "\edaf";
}
.icofont-jumping:before
{
  content: "\edb0";
}
.icofont-kick:before
{
  content: "\edb1";
}
.icofont-leg:before
{
  content: "\edb2";
}
.icofont-match-review:before
{
  content: "\edb3";
}
.icofont-medal-sport:before
{
  content: "\edb4";
}
.icofont-offside:before
{
  content: "\edb5";
}
.icofont-olympic-logo:before
{
  content: "\edb6";
}
.icofont-olympic:before
{
  content: "\edb7";
}
.icofont-padding:before
{
  content: "\edb8";
}
.icofont-penalty-card:before
{
  content: "\edb9";
}
.icofont-racer:before
{
  content: "\edba";
}
.icofont-racing-car:before
{
  content: "\edbb";
}
.icofont-racing-flag-alt:before
{
  content: "\edbc";
}
.icofont-racing-flag:before
{
  content: "\edbd";
}
.icofont-racings-wheel:before
{
  content: "\edbe";
}
.icofont-referee:before
{
  content: "\edbf";
}
.icofont-refree-jersey:before
{
  content: "\edc0";
}
.icofont-result-sport:before
{
  content: "\edc1";
}
.icofont-rugby-ball:before
{
  content: "\edc2";
}
.icofont-rugby-player:before
{
  content: "\edc3";
}
.icofont-rugby:before
{
  content: "\edc4";
}
.icofont-runner-alt-1:before
{
  content: "\edc5";
}
.icofont-runner-alt-2:before
{
  content: "\edc6";
}
.icofont-runner:before
{
  content: "\edc7";
}
.icofont-score-board:before
{
  content: "\edc8";
}
.icofont-skiing-man:before
{
  content: "\edc9";
}
.icofont-skydiving-goggles:before
{
  content: "\edca";
}
.icofont-snow-mobile:before
{
  content: "\edcb";
}
.icofont-steering:before
{
  content: "\edcc";
}
.icofont-stopwatch:before
{
  content: "\edcd";
}
.icofont-substitute:before
{
  content: "\edce";
}
.icofont-swimmer:before
{
  content: "\edcf";
}
.icofont-table-tennis:before
{
  content: "\edd0";
}
.icofont-team-alt:before
{
  content: "\edd1";
}
.icofont-team:before
{
  content: "\edd2";
}
.icofont-tennis-player:before
{
  content: "\edd3";
}
.icofont-tennis:before
{
  content: "\edd4";
}
.icofont-tracking:before
{
  content: "\edd5";
}
.icofont-trophy-alt:before
{
  content: "\edd6";
}
.icofont-trophy:before
{
  content: "\edd7";
}
.icofont-volleyball-alt:before
{
  content: "\edd8";
}
.icofont-volleyball-fire:before
{
  content: "\edd9";
}
.icofont-volleyball:before
{
  content: "\edda";
}
.icofont-water-bottle:before
{
  content: "\eddb";
}
.icofont-whistle-alt:before
{
  content: "\eddc";
}
.icofont-whistle:before
{
  content: "\eddd";
}
.icofont-win-trophy:before
{
  content: "\edde";
}
.icofont-ui-add:before
{
  content: "\ec3e";
}
.icofont-ui-alarm:before
{
  content: "\ec3f";
}
.icofont-ui-battery:before
{
  content: "\ec40";
}
.icofont-ui-block:before
{
  content: "\ec41";
}
.icofont-ui-bluetooth:before
{
  content: "\ec42";
}
.icofont-ui-brightness:before
{
  content: "\ec43";
}
.icofont-ui-browser:before
{
  content: "\ec44";
}
.icofont-ui-calendar:before
{
  content: "\ec45";
}
.icofont-ui-call:before
{
  content: "\ec46";
}
.icofont-ui-camera:before
{
  content: "\ec47";
}
.icofont-ui-cart:before
{
  content: "\ec48";
}
.icofont-ui-cell-phone:before
{
  content: "\ec49";
}
.icofont-ui-chat:before
{
  content: "\ec4a";
}
.icofont-ui-check:before
{
  content: "\ec4b";
}
.icofont-ui-clip-board:before
{
  content: "\ec4c";
}
.icofont-ui-clip:before
{
  content: "\ec4d";
}
.icofont-ui-clock:before
{
  content: "\ec4e";
}
.icofont-ui-close:before
{
  content: "\ec4f";
}
.icofont-ui-contact-list:before
{
  content: "\ec50";
}
.icofont-ui-copy:before
{
  content: "\ec51";
}
.icofont-ui-cut:before
{
  content: "\ec52";
}
.icofont-ui-delete:before
{
  content: "\ec53";
}
.icofont-ui-dial-phone:before
{
  content: "\ec54";
}
.icofont-ui-edit:before
{
  content: "\ec55";
}
.icofont-ui-email:before
{
  content: "\ec56";
}
.icofont-ui-file:before
{
  content: "\ec57";
}
.icofont-ui-fire-wall:before
{
  content: "\ec58";
}
.icofont-ui-flash-light:before
{
  content: "\ec59";
}
.icofont-ui-flight:before
{
  content: "\ec5a";
}
.icofont-ui-folder:before
{
  content: "\ec5b";
}
.icofont-ui-game:before
{
  content: "\ec5c";
}
.icofont-ui-handicapped:before
{
  content: "\ec5d";
}
.icofont-ui-home:before
{
  content: "\ec5e";
}
.icofont-ui-image:before
{
  content: "\ec5f";
}
.icofont-ui-laoding:before
{
  content: "\ec60";
}
.icofont-ui-lock:before
{
  content: "\ec61";
}
.icofont-ui-love-add:before
{
  content: "\ec62";
}
.icofont-ui-love-broken:before
{
  content: "\ec63";
}
.icofont-ui-love-remove:before
{
  content: "\ec64";
}
.icofont-ui-love:before
{
  content: "\ec65";
}
.icofont-ui-map:before
{
  content: "\ec66";
}
.icofont-ui-message:before
{
  content: "\ec67";
}
.icofont-ui-messaging:before
{
  content: "\ec68";
}
.icofont-ui-movie:before
{
  content: "\ec69";
}
.icofont-ui-music-player:before
{
  content: "\ec6a";
}
.icofont-ui-music:before
{
  content: "\ec6b";
}
.icofont-ui-mute:before
{
  content: "\ec6c";
}
.icofont-ui-network:before
{
  content: "\ec6d";
}
.icofont-ui-next:before
{
  content: "\ec6e";
}
.icofont-ui-note:before
{
  content: "\ec6f";
}
.icofont-ui-office:before
{
  content: "\ec70";
}
.icofont-ui-password:before
{
  content: "\ec71";
}
.icofont-ui-pause:before
{
  content: "\ec72";
}
.icofont-ui-play-stop:before
{
  content: "\ec73";
}
.icofont-ui-play:before
{
  content: "\ec74";
}
.icofont-ui-pointer:before
{
  content: "\ec75";
}
.icofont-ui-power:before
{
  content: "\ec76";
}
.icofont-ui-press:before
{
  content: "\ec77";
}
.icofont-ui-previous:before
{
  content: "\ec78";
}
.icofont-ui-rate-add:before
{
  content: "\ec79";
}
.icofont-ui-rate-blank:before
{
  content: "\ec7a";
}
.icofont-ui-rate-remove:before
{
  content: "\ec7b";
}
.icofont-ui-rating:before
{
  content: "\ec7c";
}
.icofont-ui-record:before
{
  content: "\ec7d";
}
.icofont-ui-remove:before
{
  content: "\ec7e";
}
.icofont-ui-reply:before
{
  content: "\ec7f";
}
.icofont-ui-rotation:before
{
  content: "\ec80";
}
.icofont-ui-rss:before
{
  content: "\ec81";
}
.icofont-ui-search:before
{
  content: "\ec82";
}
.icofont-ui-settings:before
{
  content: "\ec83";
}
.icofont-ui-social-link:before
{
  content: "\ec84";
}
.icofont-ui-tag:before
{
  content: "\ec85";
}
.icofont-ui-text-chat:before
{
  content: "\ec86";
}
.icofont-ui-text-loading:before
{
  content: "\ec87";
}
.icofont-ui-theme:before
{
  content: "\ec88";
}
.icofont-ui-timer:before
{
  content: "\ec89";
}
.icofont-ui-touch-phone:before
{
  content: "\ec8a";
}
.icofont-ui-travel:before
{
  content: "\ec8b";
}
.icofont-ui-unlock:before
{
  content: "\ec8c";
}
.icofont-ui-user-group:before
{
  content: "\ec8d";
}
.icofont-ui-user:before
{
  content: "\ec8e";
}
.icofont-ui-v-card:before
{
  content: "\ec8f";
}
.icofont-ui-video-chat:before
{
  content: "\ec90";
}
.icofont-ui-video-message:before
{
  content: "\ec91";
}
.icofont-ui-video-play:before
{
  content: "\ec92";
}
.icofont-ui-video:before
{
  content: "\ec93";
}
.icofont-ui-volume:before
{
  content: "\ec94";
}
.icofont-ui-weather:before
{
  content: "\ec95";
}
.icofont-ui-wifi:before
{
  content: "\ec96";
}
.icofont-ui-zoom-in:before
{
  content: "\ec97";
}
.icofont-ui-zoom-out:before
{
  content: "\ec98";
}
.icofont-500px:before
{
  content: "\ed1c";
}
.icofont-aim:before
{
  content: "\ed1d";
}
.icofont-badoo:before
{
  content: "\ed1e";
}
.icofont-baidu-tieba:before
{
  content: "\ed1f";
}
.icofont-bbm-messenger:before
{
  content: "\ed20";
}
.icofont-bebo:before
{
  content: "\ed21";
}
.icofont-behance:before
{
  content: "\ed22";
}
.icofont-blogger:before
{
  content: "\ed23";
}
.icofont-bootstrap:before
{
  content: "\ed24";
}
.icofont-brightkite:before
{
  content: "\ed25";
}
.icofont-cloudapp:before
{
  content: "\ed26";
}
.icofont-concrete5:before
{
  content: "\ed27";
}
.icofont-delicious:before
{
  content: "\ed28";
}
.icofont-designbump:before
{
  content: "\ed29";
}
.icofont-designfloat:before
{
  content: "\ed2a";
}
.icofont-deviantart:before
{
  content: "\ed2b";
}
.icofont-digg:before
{
  content: "\ed2c";
}
.icofont-dotcms:before
{
  content: "\ed2d";
}
.icofont-dribbble:before
{
  content: "\ed2e";
}
.icofont-dribble:before
{
  content: "\ed2f";
}
.icofont-dropbox:before
{
  content: "\ed30";
}
.icofont-ebuddy:before
{
  content: "\ed31";
}
.icofont-ello:before
{
  content: "\ed32";
}
.icofont-ember:before
{
  content: "\ed33";
}
.icofont-envato:before
{
  content: "\ed34";
}
.icofont-evernote:before
{
  content: "\ed35";
}
.icofont-facebook-messenger:before
{
  content: "\ed36";
}
.icofont-facebook:before
{
  content: "\ed37";
}
.icofont-feedburner:before
{
  content: "\ed38";
}
.icofont-flikr:before
{
  content: "\ed39";
}
.icofont-folkd:before
{
  content: "\ed3a";
}
.icofont-foursquare:before
{
  content: "\ed3b";
}
.icofont-friendfeed:before
{
  content: "\ed3c";
}
.icofont-ghost:before
{
  content: "\ed3d";
}
.icofont-github:before
{
  content: "\ed3e";
}
.icofont-gnome:before
{
  content: "\ed3f";
}
.icofont-google-buzz:before
{
  content: "\ed40";
}
.icofont-google-hangouts:before
{
  content: "\ed41";
}
.icofont-google-map:before
{
  content: "\ed42";
}
.icofont-google-plus:before
{
  content: "\ed43";
}
.icofont-google-talk:before
{
  content: "\ed44";
}
.icofont-hype-machine:before
{
  content: "\ed45";
}
.icofont-instagram:before
{
  content: "\ed46";
}
.icofont-kakaotalk:before
{
  content: "\ed47";
}
.icofont-kickstarter:before
{
  content: "\ed48";
}
.icofont-kik:before
{
  content: "\ed49";
}
.icofont-kiwibox:before
{
  content: "\ed4a";
}
.icofont-line-messenger:before
{
  content: "\ed4b";
}
.icofont-line:before
{
  content: "\ed4c";
}
.icofont-linkedin:before
{
  content: "\ed4d";
}
.icofont-linux-mint:before
{
  content: "\ed4e";
}
.icofont-live-messenger:before
{
  content: "\ed4f";
}
.icofont-livejournal:before
{
  content: "\ed50";
}
.icofont-magento:before
{
  content: "\ed51";
}
.icofont-meetme:before
{
  content: "\ed52";
}
.icofont-meetup:before
{
  content: "\ed53";
}
.icofont-mixx:before
{
  content: "\ed54";
}
.icofont-newsvine:before
{
  content: "\ed55";
}
.icofont-nimbuss:before
{
  content: "\ed56";
}
.icofont-odnoklassniki:before
{
  content: "\ed57";
}
.icofont-opencart:before
{
  content: "\ed58";
}
.icofont-oscommerce:before
{
  content: "\ed59";
}
.icofont-pandora:before
{
  content: "\ed5a";
}
.icofont-photobucket:before
{
  content: "\ed5b";
}
.icofont-picasa:before
{
  content: "\ed5c";
}
.icofont-pinterest:before
{
  content: "\ed5d";
}
.icofont-prestashop:before
{
  content: "\ed5e";
}
.icofont-qik:before
{
  content: "\ed5f";
}
.icofont-qq:before
{
  content: "\ed60";
}
.icofont-readernaut:before
{
  content: "\ed61";
}
.icofont-reddit:before
{
  content: "\ed62";
}
.icofont-renren:before
{
  content: "\ed63";
}
.icofont-rss:before
{
  content: "\ed64";
}
.icofont-shopify:before
{
  content: "\ed65";
}
.icofont-silverstripe:before
{
  content: "\ed66";
}
.icofont-skype:before
{
  content: "\ed67";
}
.icofont-slack:before
{
  content: "\ed68";
}
.icofont-slashdot:before
{
  content: "\ed69";
}
.icofont-slidshare:before
{
  content: "\ed6a";
}
.icofont-smugmug:before
{
  content: "\ed6b";
}
.icofont-snapchat:before
{
  content: "\ed6c";
}
.icofont-soundcloud:before
{
  content: "\ed6d";
}
.icofont-spotify:before
{
  content: "\ed6e";
}
.icofont-stack-exchange:before
{
  content: "\ed6f";
}
.icofont-stack-overflow:before
{
  content: "\ed70";
}
.icofont-steam:before
{
  content: "\ed71";
}
.icofont-stumbleupon:before
{
  content: "\ed72";
}
.icofont-tagged:before
{
  content: "\ed73";
}
.icofont-technorati:before
{
  content: "\ed74";
}
.icofont-telegram:before
{
  content: "\ed75";
}
.icofont-tinder:before
{
  content: "\ed76";
}
.icofont-trello:before
{
  content: "\ed77";
}
.icofont-tumblr:before
{
  content: "\ed78";
}
.icofont-twitch:before
{
  content: "\ed79";
}
.icofont-twitter:before
{
  content: "\ed7a";
}
.icofont-typo3:before
{
  content: "\ed7b";
}
.icofont-ubercart:before
{
  content: "\ed7c";
}
.icofont-viber:before
{
  content: "\ed7d";
}
.icofont-viddler:before
{
  content: "\ed7e";
}
.icofont-vimeo:before
{
  content: "\ed7f";
}
.icofont-vine:before
{
  content: "\ed80";
}
.icofont-virb:before
{
  content: "\ed81";
}
.icofont-virtuemart:before
{
  content: "\ed82";
}
.icofont-vk:before
{
  content: "\ed83";
}
.icofont-wechat:before
{
  content: "\ed84";
}
.icofont-weibo:before
{
  content: "\ed85";
}
.icofont-whatsapp:before
{
  content: "\ed86";
}
.icofont-xing:before
{
  content: "\ed87";
}
.icofont-yahoo:before
{
  content: "\ed88";
}
.icofont-yelp:before
{
  content: "\ed89";
}
.icofont-youku:before
{
  content: "\ed8a";
}
.icofont-youtube:before
{
  content: "\ed8b";
}
.icofont-zencart:before
{
  content: "\ed8c";
}
.icofont-apple:before
{
  content: "\eb3b";
}
.icofont-arabian-coffee:before
{
  content: "\eb3c";
}
.icofont-artichoke:before
{
  content: "\eb3d";
}
.icofont-asparagus:before
{
  content: "\eb3e";
}
.icofont-avocado:before
{
  content: "\eb3f";
}
.icofont-baby-food:before
{
  content: "\eb40";
}
.icofont-banana:before
{
  content: "\eb41";
}
.icofont-bbq:before
{
  content: "\eb42";
}
.icofont-beans:before
{
  content: "\eb43";
}
.icofont-beer:before
{
  content: "\eb44";
}
.icofont-bell-pepper-capsicum:before
{
  content: "\eb45";
}
.icofont-birthday-cake:before
{
  content: "\eb46";
}
.icofont-bread:before
{
  content: "\eb47";
}
.icofont-broccoli:before
{
  content: "\eb48";
}
.icofont-burger:before
{
  content: "\eb49";
}
.icofont-cabbage:before
{
  content: "\eb4a";
}
.icofont-carrot:before
{
  content: "\eb4b";
}
.icofont-cauli-flower:before
{
  content: "\eb4c";
}
.icofont-cheese:before
{
  content: "\eb4d";
}
.icofont-chef:before
{
  content: "\eb4e";
}
.icofont-cherry:before
{
  content: "\eb4f";
}
.icofont-chicken-fry:before
{
  content: "\eb50";
}
.icofont-chicken:before
{
  content: "\eb51";
}
.icofont-cocktail:before
{
  content: "\eb52";
}
.icofont-coconut-water:before
{
  content: "\eb53";
}
.icofont-coconut:before
{
  content: "\eb54";
}
.icofont-coffee-alt:before
{
  content: "\eb55";
}
.icofont-coffee-cup:before
{
  content: "\eb56";
}
.icofont-coffee-mug:before
{
  content: "\eb57";
}
.icofont-coffee-pot:before
{
  content: "\eb58";
}
.icofont-cola:before
{
  content: "\eb59";
}
.icofont-corn:before
{
  content: "\eb5a";
}
.icofont-croissant:before
{
  content: "\eb5b";
}
.icofont-crop-plant:before
{
  content: "\eb5c";
}
.icofont-cucumber:before
{
  content: "\eb5d";
}
.icofont-culinary:before
{
  content: "\eb5e";
}
.icofont-cup-cake:before
{
  content: "\eb5f";
}
.icofont-dining-table:before
{
  content: "\eb60";
}
.icofont-donut:before
{
  content: "\eb61";
}
.icofont-egg-plant:before
{
  content: "\eb62";
}
.icofont-egg-poached:before
{
  content: "\eb63";
}
.icofont-farmer-alt:before
{
  content: "\eb64";
}
.icofont-farmer:before
{
  content: "\eb65";
}
.icofont-fast-food:before
{
  content: "\eb66";
}
.icofont-food-basket:before
{
  content: "\eb67";
}
.icofont-food-cart:before
{
  content: "\eb68";
}
.icofont-fork-and-knife:before
{
  content: "\eb69";
}
.icofont-french-fries:before
{
  content: "\eb6a";
}
.icofont-fruits:before
{
  content: "\eb6b";
}
.icofont-grapes:before
{
  content: "\eb6c";
}
.icofont-honey:before
{
  content: "\eb6d";
}
.icofont-hot-dog:before
{
  content: "\eb6e";
}
.icofont-ice-cream-alt:before
{
  content: "\eb6f";
}
.icofont-ice-cream:before
{
  content: "\eb70";
}
.icofont-juice:before
{
  content: "\eb71";
}
.icofont-ketchup:before
{
  content: "\eb72";
}
.icofont-kiwi:before
{
  content: "\eb73";
}
.icofont-layered-cake:before
{
  content: "\eb74";
}
.icofont-lemon-alt:before
{
  content: "\eb75";
}
.icofont-lemon:before
{
  content: "\eb76";
}
.icofont-lobster:before
{
  content: "\eb77";
}
.icofont-mango:before
{
  content: "\eb78";
}
.icofont-milk:before
{
  content: "\eb79";
}
.icofont-mushroom:before
{
  content: "\eb7a";
}
.icofont-noodles:before
{
  content: "\eb7b";
}
.icofont-onion:before
{
  content: "\eb7c";
}
.icofont-orange:before
{
  content: "\eb7d";
}
.icofont-pear:before
{
  content: "\eb7e";
}
.icofont-peas:before
{
  content: "\eb7f";
}
.icofont-pepper:before
{
  content: "\eb80";
}
.icofont-pie-alt:before
{
  content: "\eb81";
}
.icofont-pie:before
{
  content: "\eb82";
}
.icofont-pineapple:before
{
  content: "\eb83";
}
.icofont-pizza-slice:before
{
  content: "\eb84";
}
.icofont-pizza:before
{
  content: "\eb85";
}
.icofont-plant:before
{
  content: "\eb86";
}
.icofont-popcorn:before
{
  content: "\eb87";
}
.icofont-potato:before
{
  content: "\eb88";
}
.icofont-pumpkin:before
{
  content: "\eb89";
}
.icofont-raddish:before
{
  content: "\eb8a";
}
.icofont-restaurant-menu:before
{
  content: "\eb8b";
}
.icofont-restaurant:before
{
  content: "\eb8c";
}
.icofont-salt-and-pepper:before
{
  content: "\eb8d";
}
.icofont-sandwich:before
{
  content: "\eb8e";
}
.icofont-sausage:before
{
  content: "\eb8f";
}
.icofont-soft-drinks:before
{
  content: "\eb90";
}
.icofont-soup-bowl:before
{
  content: "\eb91";
}
.icofont-spoon-and-fork:before
{
  content: "\eb92";
}
.icofont-steak:before
{
  content: "\eb93";
}
.icofont-strawberry:before
{
  content: "\eb94";
}
.icofont-sub-sandwich:before
{
  content: "\eb95";
}
.icofont-sushi:before
{
  content: "\eb96";
}
.icofont-taco:before
{
  content: "\eb97";
}
.icofont-tea-pot:before
{
  content: "\eb98";
}
.icofont-tea:before
{
  content: "\eb99";
}
.icofont-tomato:before
{
  content: "\eb9a";
}
.icofont-watermelon:before
{
  content: "\eb9b";
}
.icofont-wheat:before
{
  content: "\eb9c";
}
.icofont-afghani-false:before
{
  content: "\e9c5";
}
.icofont-afghani-minus:before
{
  content: "\e9c6";
}
.icofont-afghani-plus:before
{
  content: "\e9c7";
}
.icofont-afghani-true:before
{
  content: "\e9c8";
}
.icofont-afghani:before
{
  content: "\e9c9";
}
.icofont-baht-false:before
{
  content: "\e9ca";
}
.icofont-baht-minus:before
{
  content: "\e9cb";
}
.icofont-baht-plus:before
{
  content: "\e9cc";
}
.icofont-baht-true:before
{
  content: "\e9cd";
}
.icofont-baht:before
{
  content: "\e9ce";
}
.icofont-bitcoin-false:before
{
  content: "\e9cf";
}
.icofont-bitcoin-minus:before
{
  content: "\e9d0";
}
.icofont-bitcoin-plus:before
{
  content: "\e9d1";
}
.icofont-bitcoin-true:before
{
  content: "\e9d2";
}
.icofont-bitcoin:before
{
  content: "\e9d3";
}
.icofont-dollar-flase:before
{
  content: "\e9d4";
}
.icofont-dollar-minus:before
{
  content: "\e9d5";
}
.icofont-dollar-plus:before
{
  content: "\e9d6";
}
.icofont-dollar-true:before
{
  content: "\e9d7";
}
.icofont-dollar:before
{
  content: "\e9d8";
}
.icofont-dong-false:before
{
  content: "\e9d9";
}
.icofont-dong-minus:before
{
  content: "\e9da";
}
.icofont-dong-plus:before
{
  content: "\e9db";
}
.icofont-dong-true:before
{
  content: "\e9dc";
}
.icofont-dong:before
{
  content: "\e9dd";
}
.icofont-euro-false:before
{
  content: "\e9de";
}
.icofont-euro-minus:before
{
  content: "\e9df";
}
.icofont-euro-plus:before
{
  content: "\e9e0";
}
.icofont-euro-true:before
{
  content: "\e9e1";
}
.icofont-euro:before
{
  content: "\e9e2";
}
.icofont-frank-false:before
{
  content: "\e9e3";
}
.icofont-frank-minus:before
{
  content: "\e9e4";
}
.icofont-frank-plus:before
{
  content: "\e9e5";
}
.icofont-frank-true:before
{
  content: "\e9e6";
}
.icofont-frank:before
{
  content: "\e9e7";
}
.icofont-hryvnia-false:before
{
  content: "\e9e8";
}
.icofont-hryvnia-minus:before
{
  content: "\e9e9";
}
.icofont-hryvnia-plus:before
{
  content: "\e9ea";
}
.icofont-hryvnia-true:before
{
  content: "\e9eb";
}
.icofont-hryvnia:before
{
  content: "\e9ec";
}
.icofont-lira-false:before
{
  content: "\e9ed";
}
.icofont-lira-minus:before
{
  content: "\e9ee";
}
.icofont-lira-plus:before
{
  content: "\e9ef";
}
.icofont-lira-true:before
{
  content: "\e9f0";
}
.icofont-lira:before
{
  content: "\e9f1";
}
.icofont-peseta-false:before
{
  content: "\e9f2";
}
.icofont-peseta-minus:before
{
  content: "\e9f3";
}
.icofont-peseta-plus:before
{
  content: "\e9f4";
}
.icofont-peseta-true:before
{
  content: "\e9f5";
}
.icofont-peseta:before
{
  content: "\e9f6";
}
.icofont-peso-false:before
{
  content: "\e9f7";
}
.icofont-peso-minus:before
{
  content: "\e9f8";
}
.icofont-peso-plus:before
{
  content: "\e9f9";
}
.icofont-peso-true:before
{
  content: "\e9fa";
}
.icofont-peso:before
{
  content: "\e9fb";
}
.icofont-pound-false:before
{
  content: "\e9fc";
}
.icofont-pound-minus:before
{
  content: "\e9fd";
}
.icofont-pound-plus:before
{
  content: "\e9fe";
}
.icofont-pound-true:before
{
  content: "\e9ff";
}
.icofont-pound:before
{
  content: "\ea00";
}
.icofont-renminbi-false:before
{
  content: "\ea01";
}
.icofont-renminbi-minus:before
{
  content: "\ea02";
}
.icofont-renminbi-plus:before
{
  content: "\ea03";
}
.icofont-renminbi-true:before
{
  content: "\ea04";
}
.icofont-renminbi:before
{
  content: "\ea05";
}
.icofont-riyal-false:before
{
  content: "\ea06";
}
.icofont-riyal-minus:before
{
  content: "\ea07";
}
.icofont-riyal-plus:before
{
  content: "\ea08";
}
.icofont-riyal-true:before
{
  content: "\ea09";
}
.icofont-riyal:before
{
  content: "\ea0a";
}
.icofont-rouble-false:before
{
  content: "\ea0b";
}
.icofont-rouble-minus:before
{
  content: "\ea0c";
}
.icofont-rouble-plus:before
{
  content: "\ea0d";
}
.icofont-rouble-true:before
{
  content: "\ea0e";
}
.icofont-rouble:before
{
  content: "\ea0f";
}
.icofont-rupee-false:before
{
  content: "\ea10";
}
.icofont-rupee-minus:before
{
  content: "\ea11";
}
.icofont-rupee-plus:before
{
  content: "\ea12";
}
.icofont-rupee-true:before
{
  content: "\ea13";
}
.icofont-rupee:before
{
  content: "\ea14";
}
.icofont-taka-false:before
{
  content: "\ea15";
}
.icofont-taka-minus:before
{
  content: "\ea16";
}
.icofont-taka-plus:before
{
  content: "\ea17";
}
.icofont-taka-true:before
{
  content: "\ea18";
}
.icofont-taka:before
{
  content: "\ea19";
}
.icofont-turkish-lira-false:before
{
  content: "\ea1a";
}
.icofont-turkish-lira-minus:before
{
  content: "\ea1b";
}
.icofont-turkish-lira-plus:before
{
  content: "\ea1c";
}
.icofont-turkish-lira-true:before
{
  content: "\ea1d";
}
.icofont-turkish-lira:before
{
  content: "\ea1e";
}
.icofont-won-false:before
{
  content: "\ea1f";
}
.icofont-won-minus:before
{
  content: "\ea20";
}
.icofont-won-plus:before
{
  content: "\ea21";
}
.icofont-won-true:before
{
  content: "\ea22";
}
.icofont-won:before
{
  content: "\ea23";
}
.icofont-yen-false:before
{
  content: "\ea24";
}
.icofont-yen-minus:before
{
  content: "\ea25";
}
.icofont-yen-plus:before
{
  content: "\ea26";
}
.icofont-yen-true:before
{
  content: "\ea27";
}
.icofont-yen:before
{
  content: "\ea28";
}
.icofont-addons:before
{
  content: "\eea0";
}
.icofont-address-book:before
{
  content: "\eea1";
}
.icofont-adjust:before
{
  content: "\eea2";
}
.icofont-alarm:before
{
  content: "\eea3";
}
.icofont-anchor:before
{
  content: "\eea4";
}
.icofont-archive:before
{
  content: "\eea5";
}
.icofont-at:before
{
  content: "\eea6";
}
.icofont-attachment:before
{
  content: "\eea7";
}
.icofont-audio:before
{
  content: "\eea8";
}
.icofont-automation:before
{
  content: "\eea9";
}
.icofont-badge:before
{
  content: "\eeaa";
}
.icofont-bag-alt:before
{
  content: "\eeab";
}
.icofont-bag:before
{
  content: "\eeac";
}
.icofont-ban:before
{
  content: "\eead";
}
.icofont-bar-code:before
{
  content: "\eeae";
}
.icofont-bars:before
{
  content: "\eeaf";
}
.icofont-basket:before
{
  content: "\eeb0";
}
.icofont-battery-empty:before
{
  content: "\eeb1";
}
.icofont-battery-full:before
{
  content: "\eeb2";
}
.icofont-battery-half:before
{
  content: "\eeb3";
}
.icofont-battery-low:before
{
  content: "\eeb4";
}
.icofont-beaker:before
{
  content: "\eeb5";
}
.icofont-beard:before
{
  content: "\eeb6";
}
.icofont-bed:before
{
  content: "\eeb7";
}
.icofont-bell:before
{
  content: "\eeb8";
}
.icofont-beverage:before
{
  content: "\eeb9";
}
.icofont-bill:before
{
  content: "\eeba";
}
.icofont-bin:before
{
  content: "\eebb";
}
.icofont-binary:before
{
  content: "\eebc";
}
.icofont-binoculars:before
{
  content: "\eebd";
}
.icofont-bluetooth:before
{
  content: "\eebe";
}
.icofont-bomb:before
{
  content: "\eebf";
}
.icofont-book-mark:before
{
  content: "\eec0";
}
.icofont-box:before
{
  content: "\eec1";
}
.icofont-briefcase:before
{
  content: "\eec2";
}
.icofont-broken:before
{
  content: "\eec3";
}
.icofont-bucket:before
{
  content: "\eec4";
}
.icofont-bucket1:before
{
  content: "\eec5";
}
.icofont-bucket2:before
{
  content: "\eec6";
}
.icofont-bug:before
{
  content: "\eec7";
}
.icofont-building:before
{
  content: "\eec8";
}
.icofont-bulb-alt:before
{
  content: "\eec9";
}
.icofont-bullet:before
{
  content: "\eeca";
}
.icofont-bullhorn:before
{
  content: "\eecb";
}
.icofont-bullseye:before
{
  content: "\eecc";
}
.icofont-calendar:before
{
  content: "\eecd";
}
.icofont-camera-alt:before
{
  content: "\eece";
}
.icofont-camera:before
{
  content: "\eecf";
}
.icofont-card:before
{
  content: "\eed0";
}
.icofont-cart-alt:before
{
  content: "\eed1";
}
.icofont-cart:before
{
  content: "\eed2";
}
.icofont-cc:before
{
  content: "\eed3";
}
.icofont-charging:before
{
  content: "\eed4";
}
.icofont-chat:before
{
  content: "\eed5";
}
.icofont-check-alt:before
{
  content: "\eed6";
}
.icofont-check-circled:before
{
  content: "\eed7";
}
.icofont-check:before
{
  content: "\eed8";
}
.icofont-checked:before
{
  content: "\eed9";
}
.icofont-children-care:before
{
  content: "\eeda";
}
.icofont-clip:before
{
  content: "\eedb";
}
.icofont-clock-time:before
{
  content: "\eedc";
}
.icofont-close-circled:before
{
  content: "\eedd";
}
.icofont-close-line-circled:before
{
  content: "\eede";
}
.icofont-close-line-squared-alt:before
{
  content: "\eedf";
}
.icofont-close-line-squared:before
{
  content: "\eee0";
}
.icofont-close-line:before
{
  content: "\eee1";
}
.icofont-close-squared-alt:before
{
  content: "\eee2";
}
.icofont-close-squared:before
{
  content: "\eee3";
}
.icofont-close:before
{
  content: "\eee4";
}
.icofont-cloud-download:before
{
  content: "\eee5";
}
.icofont-cloud-refresh:before
{
  content: "\eee6";
}
.icofont-cloud-upload:before
{
  content: "\eee7";
}
.icofont-cloud:before
{
  content: "\eee8";
}
.icofont-code-not-allowed:before
{
  content: "\eee9";
}
.icofont-code:before
{
  content: "\eeea";
}
.icofont-comment:before
{
  content: "\eeeb";
}
.icofont-compass-alt:before
{
  content: "\eeec";
}
.icofont-compass:before
{
  content: "\eeed";
}
.icofont-computer:before
{
  content: "\eeee";
}
.icofont-connection:before
{
  content: "\eeef";
}
.icofont-console:before
{
  content: "\eef0";
}
.icofont-contacts:before
{
  content: "\eef1";
}
.icofont-contrast:before
{
  content: "\eef2";
}
.icofont-copyright:before
{
  content: "\eef3";
}
.icofont-credit-card:before
{
  content: "\eef4";
}
.icofont-crop:before
{
  content: "\eef5";
}
.icofont-crown:before
{
  content: "\eef6";
}
.icofont-cube:before
{
  content: "\eef7";
}
.icofont-cubes:before
{
  content: "\eef8";
}
.icofont-dashboard-web:before
{
  content: "\eef9";
}
.icofont-dashboard:before
{
  content: "\eefa";
}
.icofont-data:before
{
  content: "\eefb";
}
.icofont-database-add:before
{
  content: "\eefc";
}
.icofont-database-locked:before
{
  content: "\eefd";
}
.icofont-database-remove:before
{
  content: "\eefe";
}
.icofont-database:before
{
  content: "\eeff";
}
.icofont-delete:before
{
  content: "\ef00";
}
.icofont-diamond:before
{
  content: "\ef01";
}
.icofont-dice-multiple:before
{
  content: "\ef02";
}
.icofont-dice:before
{
  content: "\ef03";
}
.icofont-disc:before
{
  content: "\ef04";
}
.icofont-diskette:before
{
  content: "\ef05";
}
.icofont-document-folder:before
{
  content: "\ef06";
}
.icofont-download-alt:before
{
  content: "\ef07";
}
.icofont-download:before
{
  content: "\ef08";
}
.icofont-downloaded:before
{
  content: "\ef09";
}
.icofont-drag:before
{
  content: "\ef0a";
}
.icofont-drag1:before
{
  content: "\ef0b";
}
.icofont-drag2:before
{
  content: "\ef0c";
}
.icofont-drag3:before
{
  content: "\ef0d";
}
.icofont-earth:before
{
  content: "\ef0e";
}
.icofont-ebook:before
{
  content: "\ef0f";
}
.icofont-edit:before
{
  content: "\ef10";
}
.icofont-eject:before
{
  content: "\ef11";
}
.icofont-email:before
{
  content: "\ef12";
}
.icofont-envelope-open:before
{
  content: "\ef13";
}
.icofont-envelope:before
{
  content: "\ef14";
}
.icofont-eraser:before
{
  content: "\ef15";
}
.icofont-error:before
{
  content: "\ef16";
}
.icofont-excavator:before
{
  content: "\ef17";
}
.icofont-exchange:before
{
  content: "\ef18";
}
.icofont-exclamation-circle:before
{
  content: "\ef19";
}
.icofont-exclamation-square:before
{
  content: "\ef1a";
}
.icofont-exclamation-tringle:before
{
  content: "\ef1b";
}
.icofont-exclamation:before
{
  content: "\ef1c";
}
.icofont-exit:before
{
  content: "\ef1d";
}
.icofont-expand:before
{
  content: "\ef1e";
}
.icofont-external-link:before
{
  content: "\ef1f";
}
.icofont-external:before
{
  content: "\ef20";
}
.icofont-eye-alt:before
{
  content: "\ef21";
}
.icofont-eye-blocked:before
{
  content: "\ef22";
}
.icofont-eye-dropper:before
{
  content: "\ef23";
}
.icofont-eye:before
{
  content: "\ef24";
}
.icofont-favourite:before
{
  content: "\ef25";
}
.icofont-fax:before
{
  content: "\ef26";
}
.icofont-file-fill:before
{
  content: "\ef27";
}
.icofont-film:before
{
  content: "\ef28";
}
.icofont-filter:before
{
  content: "\ef29";
}
.icofont-fire-alt:before
{
  content: "\ef2a";
}
.icofont-fire-burn:before
{
  content: "\ef2b";
}
.icofont-fire:before
{
  content: "\ef2c";
}
.icofont-flag-alt-1:before
{
  content: "\ef2d";
}
.icofont-flag-alt-2:before
{
  content: "\ef2e";
}
.icofont-flag:before
{
  content: "\ef2f";
}
.icofont-flame-torch:before
{
  content: "\ef30";
}
.icofont-flash-light:before
{
  content: "\ef31";
}
.icofont-flash:before
{
  content: "\ef32";
}
.icofont-flask:before
{
  content: "\ef33";
}
.icofont-focus:before
{
  content: "\ef34";
}
.icofont-folder-open:before
{
  content: "\ef35";
}
.icofont-folder:before
{
  content: "\ef36";
}
.icofont-foot-print:before
{
  content: "\ef37";
}
.icofont-garbage:before
{
  content: "\ef38";
}
.icofont-gear-alt:before
{
  content: "\ef39";
}
.icofont-gear:before
{
  content: "\ef3a";
}
.icofont-gears:before
{
  content: "\ef3b";
}
.icofont-gift:before
{
  content: "\ef3c";
}
.icofont-glass:before
{
  content: "\ef3d";
}
.icofont-globe:before
{
  content: "\ef3e";
}
.icofont-graffiti:before
{
  content: "\ef3f";
}
.icofont-grocery:before
{
  content: "\ef40";
}
.icofont-hand:before
{
  content: "\ef41";
}
.icofont-hanger:before
{
  content: "\ef42";
}
.icofont-hard-disk:before
{
  content: "\ef43";
}
.icofont-heart-alt:before
{
  content: "\ef44";
}
.icofont-heart:before
{
  content: "\ef45";
}
.icofont-history:before
{
  content: "\ef46";
}
.icofont-home:before
{
  content: "\ef47";
}
.icofont-horn:before
{
  content: "\ef48";
}
.icofont-hour-glass:before
{
  content: "\ef49";
}
.icofont-id:before
{
  content: "\ef4a";
}
.icofont-image:before
{
  content: "\ef4b";
}
.icofont-inbox:before
{
  content: "\ef4c";
}
.icofont-infinite:before
{
  content: "\ef4d";
}
.icofont-info-circle:before
{
  content: "\ef4e";
}
.icofont-info-square:before
{
  content: "\ef4f";
}
.icofont-info:before
{
  content: "\ef50";
}
.icofont-institution:before
{
  content: "\ef51";
}
.icofont-interface:before
{
  content: "\ef52";
}
.icofont-invisible:before
{
  content: "\ef53";
}
.icofont-jacket:before
{
  content: "\ef54";
}
.icofont-jar:before
{
  content: "\ef55";
}
.icofont-jewlery:before
{
  content: "\ef56";
}
.icofont-karate:before
{
  content: "\ef57";
}
.icofont-key-hole:before
{
  content: "\ef58";
}
.icofont-key:before
{
  content: "\ef59";
}
.icofont-label:before
{
  content: "\ef5a";
}
.icofont-lamp:before
{
  content: "\ef5b";
}
.icofont-layers:before
{
  content: "\ef5c";
}
.icofont-layout:before
{
  content: "\ef5d";
}
.icofont-leaf:before
{
  content: "\ef5e";
}
.icofont-leaflet:before
{
  content: "\ef5f";
}
.icofont-learn:before
{
  content: "\ef60";
}
.icofont-lego:before
{
  content: "\ef61";
}
.icofont-lens:before
{
  content: "\ef62";
}
.icofont-letter:before
{
  content: "\ef63";
}
.icofont-letterbox:before
{
  content: "\ef64";
}
.icofont-library:before
{
  content: "\ef65";
}
.icofont-license:before
{
  content: "\ef66";
}
.icofont-life-bouy:before
{
  content: "\ef67";
}
.icofont-life-buoy:before
{
  content: "\ef68";
}
.icofont-life-jacket:before
{
  content: "\ef69";
}
.icofont-life-ring:before
{
  content: "\ef6a";
}
.icofont-light-bulb:before
{
  content: "\ef6b";
}
.icofont-lighter:before
{
  content: "\ef6c";
}
.icofont-lightning-ray:before
{
  content: "\ef6d";
}
.icofont-like:before
{
  content: "\ef6e";
}
.icofont-line-height:before
{
  content: "\ef6f";
}
.icofont-link-alt:before
{
  content: "\ef70";
}
.icofont-link:before
{
  content: "\ef71";
}
.icofont-list:before
{
  content: "\ef72";
}
.icofont-listening:before
{
  content: "\ef73";
}
.icofont-listine-dots:before
{
  content: "\ef74";
}
.icofont-listing-box:before
{
  content: "\ef75";
}
.icofont-listing-number:before
{
  content: "\ef76";
}
.icofont-live-support:before
{
  content: "\ef77";
}
.icofont-location-arrow:before
{
  content: "\ef78";
}
.icofont-location-pin:before
{
  content: "\ef79";
}
.icofont-lock:before
{
  content: "\ef7a";
}
.icofont-login:before
{
  content: "\ef7b";
}
.icofont-logout:before
{
  content: "\ef7c";
}
.icofont-lollipop:before
{
  content: "\ef7d";
}
.icofont-long-drive:before
{
  content: "\ef7e";
}
.icofont-look:before
{
  content: "\ef7f";
}
.icofont-loop:before
{
  content: "\ef80";
}
.icofont-luggage:before
{
  content: "\ef81";
}
.icofont-lunch:before
{
  content: "\ef82";
}
.icofont-lungs:before
{
  content: "\ef83";
}
.icofont-magic-alt:before
{
  content: "\ef84";
}
.icofont-magic:before
{
  content: "\ef85";
}
.icofont-magnet:before
{
  content: "\ef86";
}
.icofont-mail-box:before
{
  content: "\ef87";
}
.icofont-mail:before
{
  content: "\ef88";
}
.icofont-male:before
{
  content: "\ef89";
}
.icofont-map-pins:before
{
  content: "\ef8a";
}
.icofont-map:before
{
  content: "\ef8b";
}
.icofont-maximize:before
{
  content: "\ef8c";
}
.icofont-measure:before
{
  content: "\ef8d";
}
.icofont-medicine:before
{
  content: "\ef8e";
}
.icofont-mega-phone:before
{
  content: "\ef8f";
}
.icofont-megaphone-alt:before
{
  content: "\ef90";
}
.icofont-megaphone:before
{
  content: "\ef91";
}
.icofont-memorial:before
{
  content: "\ef92";
}
.icofont-memory-card:before
{
  content: "\ef93";
}
.icofont-mic-mute:before
{
  content: "\ef94";
}
.icofont-mic:before
{
  content: "\ef95";
}
.icofont-military:before
{
  content: "\ef96";
}
.icofont-mill:before
{
  content: "\ef97";
}
.icofont-minus-circle:before
{
  content: "\ef98";
}
.icofont-minus-square:before
{
  content: "\ef99";
}
.icofont-minus:before
{
  content: "\ef9a";
}
.icofont-mobile-phone:before
{
  content: "\ef9b";
}
.icofont-molecule:before
{
  content: "\ef9c";
}
.icofont-money:before
{
  content: "\ef9d";
}
.icofont-moon:before
{
  content: "\ef9e";
}
.icofont-mop:before
{
  content: "\ef9f";
}
.icofont-muffin:before
{
  content: "\efa0";
}
.icofont-mustache:before
{
  content: "\efa1";
}
.icofont-navigation-menu:before
{
  content: "\efa2";
}
.icofont-navigation:before
{
  content: "\efa3";
}
.icofont-network-tower:before
{
  content: "\efa4";
}
.icofont-network:before
{
  content: "\efa5";
}
.icofont-news:before
{
  content: "\efa6";
}
.icofont-newspaper:before
{
  content: "\efa7";
}
.icofont-no-smoking:before
{
  content: "\efa8";
}
.icofont-not-allowed:before
{
  content: "\efa9";
}
.icofont-notebook:before
{
  content: "\efaa";
}
.icofont-notepad:before
{
  content: "\efab";
}
.icofont-notification:before
{
  content: "\efac";
}
.icofont-numbered:before
{
  content: "\efad";
}
.icofont-opposite:before
{
  content: "\efae";
}
.icofont-optic:before
{
  content: "\efaf";
}
.icofont-options:before
{
  content: "\efb0";
}
.icofont-package:before
{
  content: "\efb1";
}
.icofont-page:before
{
  content: "\efb2";
}
.icofont-paint:before
{
  content: "\efb3";
}
.icofont-paper-plane:before
{
  content: "\efb4";
}
.icofont-paperclip:before
{
  content: "\efb5";
}
.icofont-papers:before
{
  content: "\efb6";
}
.icofont-pay:before
{
  content: "\efb7";
}
.icofont-penguin-linux:before
{
  content: "\efb8";
}
.icofont-pestle:before
{
  content: "\efb9";
}
.icofont-phone-circle:before
{
  content: "\efba";
}
.icofont-phone:before
{
  content: "\efbb";
}
.icofont-picture:before
{
  content: "\efbc";
}
.icofont-pine:before
{
  content: "\efbd";
}
.icofont-pixels:before
{
  content: "\efbe";
}
.icofont-plugin:before
{
  content: "\efbf";
}
.icofont-plus-circle:before
{
  content: "\efc0";
}
.icofont-plus-square:before
{
  content: "\efc1";
}
.icofont-plus:before
{
  content: "\efc2";
}
.icofont-polygonal:before
{
  content: "\efc3";
}
.icofont-power:before
{
  content: "\efc4";
}
.icofont-price:before
{
  content: "\efc5";
}
.icofont-print:before
{
  content: "\efc6";
}
.icofont-puzzle:before
{
  content: "\efc7";
}
.icofont-qr-code:before
{
  content: "\efc8";
}
.icofont-queen:before
{
  content: "\efc9";
}
.icofont-question-circle:before
{
  content: "\efca";
}
.icofont-question-square:before
{
  content: "\efcb";
}
.icofont-question:before
{
  content: "\efcc";
}
.icofont-quote-left:before
{
  content: "\efcd";
}
.icofont-quote-right:before
{
  content: "\efce";
}
.icofont-random:before
{
  content: "\efcf";
}
.icofont-recycle:before
{
  content: "\efd0";
}
.icofont-refresh:before
{
  content: "\efd1";
}
.icofont-repair:before
{
  content: "\efd2";
}
.icofont-reply-all:before
{
  content: "\efd3";
}
.icofont-reply:before
{
  content: "\efd4";
}
.icofont-resize:before
{
  content: "\efd5";
}
.icofont-responsive:before
{
  content: "\efd6";
}
.icofont-retweet:before
{
  content: "\efd7";
}
.icofont-road:before
{
  content: "\efd8";
}
.icofont-robot:before
{
  content: "\efd9";
}
.icofont-royal:before
{
  content: "\efda";
}
.icofont-rss-feed:before
{
  content: "\efdb";
}
.icofont-safety:before
{
  content: "\efdc";
}
.icofont-sale-discount:before
{
  content: "\efdd";
}
.icofont-satellite:before
{
  content: "\efde";
}
.icofont-send-mail:before
{
  content: "\efdf";
}
.icofont-server:before
{
  content: "\efe0";
}
.icofont-settings-alt:before
{
  content: "\efe1";
}
.icofont-settings:before
{
  content: "\efe2";
}
.icofont-share-alt:before
{
  content: "\efe3";
}
.icofont-share-boxed:before
{
  content: "\efe4";
}
.icofont-share:before
{
  content: "\efe5";
}
.icofont-shield:before
{
  content: "\efe6";
}
.icofont-shopping-cart:before
{
  content: "\efe7";
}
.icofont-sign-in:before
{
  content: "\efe8";
}
.icofont-sign-out:before
{
  content: "\efe9";
}
.icofont-signal:before
{
  content: "\efea";
}
.icofont-site-map:before
{
  content: "\efeb";
}
.icofont-smart-phone:before
{
  content: "\efec";
}
.icofont-soccer:before
{
  content: "\efed";
}
.icofont-sort-alt:before
{
  content: "\efee";
}
.icofont-sort:before
{
  content: "\efef";
}
.icofont-space:before
{
  content: "\eff0";
}
.icofont-spanner:before
{
  content: "\eff1";
}
.icofont-speech-comments:before
{
  content: "\eff2";
}
.icofont-speed-meter:before
{
  content: "\eff3";
}
.icofont-spinner-alt-1:before
{
  content: "\eff4";
}
.icofont-spinner-alt-2:before
{
  content: "\eff5";
}
.icofont-spinner-alt-3:before
{
  content: "\eff6";
}
.icofont-spinner-alt-4:before
{
  content: "\eff7";
}
.icofont-spinner-alt-5:before
{
  content: "\eff8";
}
.icofont-spinner-alt-6:before
{
  content: "\eff9";
}
.icofont-spinner:before
{
  content: "\effa";
}
.icofont-spreadsheet:before
{
  content: "\effb";
}
.icofont-square:before
{
  content: "\effc";
}
.icofont-ssl-security:before
{
  content: "\effd";
}
.icofont-star-alt-1:before
{
  content: "\effe";
}
.icofont-star-alt-2:before
{
  content: "\efff";
}
.icofont-star:before
{
  content: "\f000";
}
.icofont-street-view:before
{
  content: "\f001";
}
.icofont-support-faq:before
{
  content: "\f002";
}
.icofont-tack-pin:before
{
  content: "\f003";
}
.icofont-tag:before
{
  content: "\f004";
}
.icofont-tags:before
{
  content: "\f005";
}
.icofont-tasks-alt:before
{
  content: "\f006";
}
.icofont-tasks:before
{
  content: "\f007";
}
.icofont-telephone:before
{
  content: "\f008";
}
.icofont-telescope:before
{
  content: "\f009";
}
.icofont-terminal:before
{
  content: "\f00a";
}
.icofont-thumbs-down:before
{
  content: "\f00b";
}
.icofont-thumbs-up:before
{
  content: "\f00c";
}
.icofont-tick-boxed:before
{
  content: "\f00d";
}
.icofont-tick-mark:before
{
  content: "\f00e";
}
.icofont-ticket:before
{
  content: "\f00f";
}
.icofont-tie:before
{
  content: "\f010";
}
.icofont-toggle-off:before
{
  content: "\f011";
}
.icofont-toggle-on:before
{
  content: "\f012";
}
.icofont-tools-alt-2:before
{
  content: "\f013";
}
.icofont-tools:before
{
  content: "\f014";
}
.icofont-touch:before
{
  content: "\f015";
}
.icofont-traffic-light:before
{
  content: "\f016";
}
.icofont-transparent:before
{
  content: "\f017";
}
.icofont-tree:before
{
  content: "\f018";
}
.icofont-unique-idea:before
{
  content: "\f019";
}
.icofont-unlock:before
{
  content: "\f01a";
}
.icofont-unlocked:before
{
  content: "\f01b";
}
.icofont-upload-alt:before
{
  content: "\f01c";
}
.icofont-upload:before
{
  content: "\f01d";
}
.icofont-usb-drive:before
{
  content: "\f01e";
}
.icofont-usb:before
{
  content: "\f01f";
}
.icofont-vector-path:before
{
  content: "\f020";
}
.icofont-verification-check:before
{
  content: "\f021";
}
.icofont-wall-clock:before
{
  content: "\f022";
}
.icofont-wall:before
{
  content: "\f023";
}
.icofont-wallet:before
{
  content: "\f024";
}
.icofont-warning-alt:before
{
  content: "\f025";
}
.icofont-warning:before
{
  content: "\f026";
}
.icofont-water-drop:before
{
  content: "\f027";
}
.icofont-web:before
{
  content: "\f028";
}
.icofont-wheelchair:before
{
  content: "\f029";
}
.icofont-wifi-alt:before
{
  content: "\f02a";
}
.icofont-wifi:before
{
  content: "\f02b";
}
.icofont-world:before
{
  content: "\f02c";
}
.icofont-zigzag:before
{
  content: "\f02d";
}
.icofont-zipped:before
{
  content: "\f02e";
}
.icofont-burglar:before
{
  content: "\ebb0";
}
.icofont-cannon-firing:before
{
  content: "\ebb1";
}
.icofont-cc-camera:before
{
  content: "\ebb2";
}
.icofont-cop-badge:before
{
  content: "\ebb3";
}
.icofont-cop:before
{
  content: "\ebb4";
}
.icofont-court-hammer:before
{
  content: "\ebb5";
}
.icofont-court:before
{
  content: "\ebb6";
}
.icofont-finger-print:before
{
  content: "\ebb7";
}
.icofont-gavel:before
{
  content: "\ebb8";
}
.icofont-handcuff-alt:before
{
  content: "\ebb9";
}
.icofont-handcuff:before
{
  content: "\ebba";
}
.icofont-investigation:before
{
  content: "\ebbb";
}
.icofont-investigator:before
{
  content: "\ebbc";
}
.icofont-jail:before
{
  content: "\ebbd";
}
.icofont-judge:before
{
  content: "\ebbe";
}
.icofont-law-alt-1:before
{
  content: "\ebbf";
}
.icofont-law-alt-2:before
{
  content: "\ebc0";
}
.icofont-law-alt-3:before
{
  content: "\ebc1";
}
.icofont-law-book:before
{
  content: "\ebc2";
}
.icofont-law-document:before
{
  content: "\ebc3";
}
.icofont-law-order:before
{
  content: "\ebc4";
}
.icofont-law-protect:before
{
  content: "\ebc5";
}
.icofont-law-scales:before
{
  content: "\ebc6";
}
.icofont-law:before
{
  content: "\ebc7";
}
.icofont-lawyer-alt-1:before
{
  content: "\ebc8";
}
.icofont-lawyer-alt-2:before
{
  content: "\ebc9";
}
.icofont-lawyer:before
{
  content: "\ebca";
}
.icofont-legal:before
{
  content: "\ebcb";
}
.icofont-pistol:before
{
  content: "\ebcc";
}
.icofont-police-badge:before
{
  content: "\ebcd";
}
.icofont-police-cap:before
{
  content: "\ebce";
}
.icofont-police-car-alt-1:before
{
  content: "\ebcf";
}
.icofont-police-car-alt-2:before
{
  content: "\ebd0";
}
.icofont-police-car:before
{
  content: "\ebd1";
}
.icofont-police-hat:before
{
  content: "\ebd2";
}
.icofont-police-van:before
{
  content: "\ebd3";
}
.icofont-police:before
{
  content: "\ebd4";
}
.icofont-thief-alt:before
{
  content: "\ebd5";
}
.icofont-thief:before
{
  content: "\ebd6";
}